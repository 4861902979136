import { useState, useEffect } from 'react';
import axios from 'axios';
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import { api } from '../utils/enviornment';

export const useCustomerMetaData = () => {
  const [records, setRecords] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRecords = async () => {
      setLoading(true);
      setError(null);

      try {
        const { tokens } = await fetchAuthSession();
        if (!tokens?.accessToken) {
          throw new Error('No access token available');
        }

        const { userId } = await getCurrentUser();
        const apiUrl = `${api}users/${userId}`;

        const response = await axios.get(apiUrl, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens.accessToken.toString()}`,
          },
        });

        setRecords(response.data);
      } catch (err) {
        console.error('Error fetching records:', err);
        // setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchRecords();
  }, []);

  return { records, loading, error };
};
