import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { setEmail } from "../../../redux/email";
import { setFullName } from "../../../redux/name";
// import { setPostalAddress } from "../../../redux/name";

export default function AddressForm() {
  const dispatch = useDispatch();

  const handleChangeFirstName = (event) => {
    event.preventDefault();
    dispatch(setEmail(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeLastName = (event) => {
    event.preventDefault();
    dispatch(setEmail(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeAddressOne = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeAddressTwo = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeAddressCity = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeAddressState = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeAddressZipCode = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeAddressCountry = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Shipping address
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChangeFirstName}
            required
            id="firstName"
            name="firstName"
            label="First name"
            fullWidth
            autoComplete="given-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChangeLastName}
            required
            id="lastName"
            name="lastName"
            label="Last name"
            fullWidth
            autoComplete="family-name"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={handleChangeAddressOne}
            required
            id="address1"
            name="address1"
            label="Address line 1"
            fullWidth
            autoComplete="shipping address-line1"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            onChange={handleChangeAddressTwo}
            id="address2"
            name="address2"
            label="Address line 2"
            fullWidth
            autoComplete="shipping address-line2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChangeAddressCity}
            required
            id="city"
            name="city"
            label="City"
            fullWidth
            autoComplete="shipping address-level2"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChangeAddressState}
            id="state"
            name="state"
            label="State/Province/Region"
            fullWidth
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChangeAddressZipCode}
            required
            id="zip"
            name="zip"
            label="Zip / Postal code"
            fullWidth
            autoComplete="shipping postal-code"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            onChange={handleChangeAddressCountry}
            required
            id="country"
            name="country"
            label="Country"
            fullWidth
            autoComplete="shipping country"
            variant="standard"
          />
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}
