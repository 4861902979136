import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import { Grid, ThemeProvider } from '@mui/material';

import { createTheme } from '@mui/material/styles';
import { Snackbar, Alert } from '@mui/material';
import emailjs from '@emailjs/browser';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import EmailIcon from '@mui/icons-material/Email';
import './ContactElement.css';
import { FaDiscord } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import { useForm } from 'react-hook-form';
import { FaTelegram } from 'react-icons/fa';
// import Button from "./ContactElements";

const theme = createTheme({
  // typography: {
  //   subtitle1: {
  //     fontSize: 12,
  //   },
  //   body1: {
  //     fontWeight: 500,
  //   },
  //   button: {
  //     fontStyle: "italic",
  //   },
  // },
});

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction='down' in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default function ContactUs(props) {
  const [open, setOpen] = React.useState(false);
  const [val, setVal] = useState();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_62ldhtt',
        'template_bz11cal',
        e.target,
        'user_s0fxzAFroOJchojSkP7cv'
      )
      .then(
        (result) => {
          setOpen(true);
          setVal('');
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    console.log(data);
    console.log(errors);
    setOpen(true);
    reset();
  };

  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          minHeight: '100vh',
          backgroundColor: '#5e636e',
        }}
      >
        <React.Fragment>
          <CssBaseline />

          <Toolbar />

          <Grid
            container
            direction='column'
            alignItems='center'
            spacing={5}
            sx={{ borderbottom: '5px solid #7a0000' }}
          >
            <Grid item xl={1} lg={1} margin>
              <Typography
                sx={{
                  color: 'white',
                  // fontSize: "2rem",
                  fontWeight: 'bold',
                  fontFamily: 'Playfair Display',
                }}
                variant='h2'
              >
                Contact
              </Typography>
            </Grid>
            <Grid item xl={12} lg={12} sx={{ textAlign: 'center' }}>
              <Typography
                sx={{
                  color: 'white',
                  fontFamily: 'Playfair Display',
                  borderBottom: '1px solid white',
                }}
                variant='h5'
              >
                Drop us a message with your contact information / desired miner
                specs and we'll get back to you as soon as possible.
              </Typography>
            </Grid>
          </Grid>
          <Grid
            MarginBottom={50}
            container
            direction='row'
            alignItems='center'
            justifyContent='center'
            // spacing={30}
          >
            <Grid
              MarginBottom={50}
              alignItems='center'
              justifyContent='flex-end'
              container
              spacing={8}
              columns={16}
            >
              <Grid item xs={12} lg={8} xl={8}>
                <div className='hide'>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '32px',
                      lineHeight: '1.7',
                      fontFamily:
                        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
                    }}
                  >
                    <LocationOnIcon />
                    Toronto, Ontario, Canada
                  </Typography>
                </div>
                <div className='hide'>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '32px',
                      lineHeight: '1.7',
                      fontFamily:
                        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
                    }}
                  >
                    <div className='hide'>
                      <PhoneIphoneIcon /> +1 (438) 921 - 3447
                    </div>
                  </Typography>
                </div>
                <div className='hide'>
                  <Typography
                    sx={{
                      color: 'white',
                      fontSize: '32px',
                      lineHeight: '1.7',
                      fontFamily:
                        '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;',
                    }}
                  >
                    <EmailIcon /> dmpsmining@gmail.com
                  </Typography>
                </div>

                <div className='hide' style={{ fontSize: '2em' }}>
                  <FaDiscord
                    style={{
                      cursor: 'pointer',
                      color: 'white',
                    }}
                    onClick={() => {
                      window.location = 'https://discord.gg/GXfPSCZRCU';
                    }}
                    data-cy='discord'
                  />
                  <FiMail
                    style={{
                      cursor: 'pointer',
                      marginLeft: '5px',
                      color: 'white',
                    }}
                    onClick={() => {
                      window.location.href =
                        'mailto:dmpsmining@gmail.com?subject=Subject&body=I would like to inquire about your products or services.';
                    }}
                    data-cy='email'
                  />
                  <FaWhatsapp
                    style={{
                      cursor: 'pointer',
                      marginLeft: '5px',
                      color: 'white',
                    }}
                    onClick={() => {
                      window.open('tel:4389213447');
                    }}
                    data-cy='whatsapp'
                  ></FaWhatsapp>
                  <FaTelegram
                    style={{
                      cursor: 'pointer',
                      marginLeft: '5px',
                      color: 'white',
                    }}
                    onClick={() => {
                      window.location = 'https://t.me/dmpsmining';
                    }}
                    data-cy='telegram'
                  />
                </div>
              </Grid>

              <Grid container item xs={12} lg={6} xl={6} marginTop={10}>
                <Grid item xs={12} lg={12} xl={12} margin>
                  <form onSubmit={(handleSubmit(onSubmit), sendEmail)}>
                    <input
                      className='contact'
                      type='text'
                      placeholder='Name'
                      {...register('Name', {
                        required: true,
                        max: 50,
                        min: 0,
                        maxLength: 80,
                      })}
                      name='from_name'
                      value={val}
                      data-cy='name'
                    />
                    <input
                      className='contact'
                      type='tel'
                      placeholder='Mobile number'
                      {...register('Mobile number')}
                      name='from_number'
                      value={val}
                      data-cy='mobile'
                    />
                    <input
                      className='contact'
                      type='email'
                      placeholder='Email'
                      {...register('Email', {
                        required: true,
                        maxLength: 100,
                      })}
                      name='from_email'
                      value={val}
                      data-cy='email'
                    />
                    <select
                      className='contact-select'
                      {...register('gender')}
                      name='from_product'
                      data-cy='product'
                    >
                      <option value='Buy Miner ' data-cy='buyMiner'>
                        Buy Miner
                      </option>
                      <option value='Host Miner' data-cy='hostMiner'>
                        Host Miner
                      </option>
                      <option value='Buy Hashrate' data-cy='buyHashrate'>
                        Buy Hashrate
                      </option>
                      <option value='Other' data-cy='other'>
                        Other
                      </option>
                    </select>
                    <input
                      className='contact'
                      placeholder='Message'
                      {...register('Message', {
                        required: true,
                        maxLength: 500,
                      })}
                      name='message'
                      value={val}
                      data-cy='message'
                    />

                    <input
                      data-cy='submit'
                      type='submit'
                      className='contact'
                      value='Send'
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  </form>
                  <Snackbar open={open} onClose={handleClose}>
                    <Alert
                      variant='filled'
                      severity='success'
                      onClose={handleClose}
                      data-cy='alert'
                    >
                      {'Message sent!'}
                    </Alert>
                  </Snackbar>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </React.Fragment>
      </div>
    </ThemeProvider>
  );
}
