import useAxios from 'axios-hooks';
import { api } from '../utils/enviornment';
import { useState, useEffect } from 'react';
import { DMPSUserData } from '../model/model';

export function useAllCustomerMetaData() {
  const [{ data, loading, error }, refetch] = useAxios(
    {
      url: api + `getCustomerMetaData`,
      method: 'GET',
    },
    { useCache: true }
  );
  const [records, setRecords] = useState<DMPSUserData[]>([]);

  useEffect(() => {
    if (data) {
      setRecords(data.data);
    }
  }, [data]);

  return {
    isLoading: loading,
    records,
    error,
    data,
    refetch,
  };
}
