import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Cardinfo({
  img,
  title,
  subheader,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  specs,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      data-cy='hosting-services-card'
      sx={{ maxWidth: 500, width: 300, borderRadius: '25px' }}
    >
      <CardHeader
        title={title}
        subheader={subheader}
        sx={{
          textAlign: 'center',
          justifyContent: 'center',
        }}
      />
      <CardMedia
        component='img'
        height='50'
        image={img}
        alt='Miner'
        sx={{ height: '100%' }}
      />
      <CardContent>
        <Typography
          variant='body2'
          color='text.secondary'
          sx={{ textAlign: 'center', justifyContent: 'center' }}
        >
          {specs}
        </Typography>
      </CardContent>
      <CardActions disableSpacing>
        <Button
          color='success'
          variant='contained'
          size='medium'
          sx={{ margin: 'auto', width: '80%' }}
          to='/hosting'
          href='/hosting'
        >
          Get Started
        </Button>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>
      <Collapse in={expanded} timeout='auto' unmountOnExit>
        <CardContent>
          <Typography paragraph>Hosting Package Details: </Typography>
          <Typography paragraph>{description1}</Typography>
          <Typography paragraph>{description2}</Typography>
          <Typography paragraph>{description3}</Typography>
          <Typography paragraph>{description4}</Typography>
          <Typography paragraph>{description5}</Typography>
          <Typography paragraph>{description6}</Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
