import * as React from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import CssBaseline from '@mui/material/CssBaseline'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import Link from '@mui/material/Link'
import { createTheme, ThemeProvider } from '@mui/material/styles'
function Copyright () {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' href='/'>
        DMPS Mining
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  )
}

const theme = createTheme()

export default function Album () {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <main>
        {/* Hero unit */}
        <Box
          sx={{
            // backgroundColor: "#5e636e",
            pt: 8,
            pb: 6
          }}
        >
          <Container maxWidth='sm'>
            <Typography
              component='h1'
              variant='h2'
              align='center'
              color='text.primary'
              gutterBottom
            >
              Meet the Team
            </Typography>
            <Typography
              variant='h5'
              align='center'
              color='text.secondary'
              paragraph
            >
              The core members of the DMPS team!
            </Typography>
            <Stack
              sx={{ pt: 4 }}
              direction='row'
              spacing={2}
              justifyContent='center'
            ></Stack>
          </Container>
        </Box>
        <Container sx={{ py: 8 }} maxWidth='md'>
          {/* End hero unit */}
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <CardMedia
                  component='img'
                  sx={{
                    // 16:9
                    pt: '56.25%'
                  }}
                  image='https://source.unsplash.com/random'
                  alt='random'
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Derek Poon
                  </Typography>
                  <Typography>CEO / Founder</Typography>
                </CardContent>
                <CardActions>
                  <Button href='/contact' size='small'>
                    Contact
                  </Button>
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <CardMedia
                  component='img'
                  sx={{
                    // 16:9
                    pt: '56.25%'
                  }}
                  image='https://source.unsplash.com/random'
                  alt='random'
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Samuel Lawrence
                  </Typography>
                  <Typography>Senior Engineer </Typography>
                </CardContent>
                <CardActions>
                  {/* <Button size="small">Contact</Button> */}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <CardMedia
                  component='img'
                  sx={{
                    // 16:9
                    pt: '56.25%'
                  }}
                  image='https://source.unsplash.com/random'
                  alt='random'
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Santosh Samtani
                  </Typography>
                  <Typography>Customer Success Leader</Typography>
                </CardContent>
                <CardActions>
                  {/* <Button size="small">Contact</Button> */}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <CardMedia
                  component='img'
                  sx={{
                    // 16:9
                    pt: '56.25%'
                  }}
                  image='https://source.unsplash.com/random'
                  alt='random'
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Matthew Huang
                  </Typography>
                  <Typography>Junior Software developer</Typography>
                </CardContent>
                <CardActions>
                  {/* <Button size="small">Contact</Button> */}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={4}>
              <Card
                sx={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
                <CardMedia
                  component='img'
                  sx={{
                    // 16:9
                    pt: '56.25%'
                  }}
                  image='https://source.unsplash.com/random'
                  alt='random'
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography gutterBottom variant='h5' component='h2'>
                    Jason Chen
                  </Typography>
                  <Typography>Support staff</Typography>
                </CardContent>
                <CardActions>
                  {/* <Button size="small">Contact</Button> */}
                </CardActions>
              </Card>
            </Grid>
            <Grid item xs={12} sm={6} md={4} xl={4}></Grid>
          </Grid>
        </Container>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component='footer'>
        <Typography variant='h6' align='center' gutterBottom>
          Footer
        </Typography>
        <Typography
          variant='subtitle1'
          align='center'
          color='text.secondary'
          component='p'
        >
          {/* Something here to give the footer a purpose! */}
        </Typography>
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
  )
}
