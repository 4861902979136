import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";

const SignUpSuccess = () => {
  return (
    <Container
      maxWidth="xl"
      sx={{
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        bgcolor: "grey.900",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          p: 4,
          bgcolor: "#01bf71",
          color: "grey.100",
          borderRadius: 1,
          boxShadow: 3,
          width: "100%",
        }}
        component={Paper}
      >
        <Typography variant="h4" gutterBottom>
          Congratulations!
        </Typography>
        <Typography variant="h6" textAlign="center">
          You have successfully signed up for an account! Please check your
          email for a confirmation
        </Typography>
      </Box>
    </Container>
  );
};

export default SignUpSuccess;
