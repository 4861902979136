import Icon from '../../images/Bitcoinlogo/bitcoincolor4.svg';

export const homeObjOne = {
  id: 'Facility',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Premium Hosting Service',
  headline: 'Hosting and Facility information',
  description:
    'Hosting in Canada available soon in our new mining facility located in New Brunswick. The facility is currently under construction and assuming no more delays, it is on track to be online by end of May 2022. Limited space available. For more detailed updates contact us and request to be added on to the mailing list.',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: Icon,
  alt: 'btc1',
  dark: true,
  primary: true,
  darkText: false,
  dark2: true,
};

export const homeObjTwo = {
  id: 'Packages',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Purchase Hardware',
  headline: 'Get live updates of your bitcoin earnings anytime',
  description:
    'With a guaranteed uptime of xx% and a 24/7 support team, we are able to provide you with the best bitcoin mining service',
  buttonLabel: 'Learn More',
  imgStart: true,
  img: require('../../images/Bitcoinlogo/bitcoin.svg'),
  alt: 'btc2',
  dark: false,
  primary: false,
  darkText: true,
};

export const homeObjThree = {
  id: 'signup',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Get your spot today!',
  headline: 'Getting started is extremely easy',
  description:
    'Get 5% discount when you pay with crypto currency and get access to our exclusive mining service',
  buttonLabel: 'Start Now',
  imgStart: false,
  img: require('../../images/Bitcoinlogo/bitcoincolor3.svg'),
  alt: 'btc3',
  dark: false,
  primary: false,
  darkText: true,
};
