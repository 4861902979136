import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import { FaTelegram } from 'react-icons/fa';
import { FaDiscord } from 'react-icons/fa';
import { FiMail } from 'react-icons/fi';
import { FaWhatsapp } from 'react-icons/fa';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Icon1 from '../../images/Bitcoinlogo/bitcoincolor.svg';

import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
  SocialWrap,
  Input,
  Button,
} from './InfoElements';
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});
const InfoSection = ({
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  img,
  alt,
  id,
  dark,
}) => {
  const [val, setVal] = useState();
  const [open, setOpen] = React.useState(false);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_62ldhtt',
        'template_yhnhe11',
        e.target,
        'user_s0fxzAFroOJchojSkP7cv'
      )
      .then(
        (result) => {
          setOpen(true);
          setVal('');
          // window.location.reload(); //This is if you still want the page to reload (since e.preventDefault() cancelled that behavior)
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <InfoContainer id={id} data-cy='message-section'>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
            <Column1>
              <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading lightText={lightText}>{headline}</Heading>
                <Subtitle>{description}</Subtitle>
                <BtnWrap>
                  <form onSubmit={sendEmail}>
                    <Input
                      data-cy='message-to-send'
                      value={val}
                      placeholder='Have inquires? Drop us your email'
                      name='from_email'
                      type='email'
                    ></Input>
                    <Button
                      data-cy='send-message'
                      type='submit'
                      value='Send'
                      dark={dark}
                    >
                      Send
                    </Button>
                    <Snackbar
                      open={open}
                      autoHideDuration={6000}
                      onClose={handleClose}
                    >
                      <Alert
                        data-cy='alert'
                        onClose={handleClose}
                        severity='success'
                        sx={{ width: '100%' }}
                      >
                        Message sent
                      </Alert>
                    </Snackbar>
                  </form>
                </BtnWrap>
                <SocialWrap data-cy='socials'>
                  <Heading>
                    <FaDiscord
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.location = 'https://discord.gg/GXfPSCZRCU';
                      }}
                    />
                    <FiMail
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.location.href =
                          'mailto:dmpsmining@gmail.com?subject=Subject&body=I would like to inquire about your products or services.';
                      }}
                    />
                    <FaWhatsapp
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.open('tel:4389213447');
                      }}
                    />
                    <FaTelegram
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        window.location = 'https://t.me/dmpsmining';
                      }}
                    />
                  </Heading>
                </SocialWrap>
              </TextWrapper>
            </Column1>
            <Column2>
              <ImgWrap>
                <Img src={Icon1} />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
