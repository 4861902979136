import React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import AssignmentIcon from '@mui/icons-material/Assignment';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import { useHistory } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';

function HomeIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path d='M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z' />
    </SvgIcon>
  );
}

export const MainListItems = () => {
  const history = useHistory();

  return (
    <div>
      <ListItem disablePadding data-cy='homeListItem'>
        <ListItemButton onClick={() => history.push('/')} data-cy='homeButton'>
          <ListItemIcon data-cy='adminDash'>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary='Home' data-cy='homeText' />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-cy='dashboardListItem'>
        <ListItemButton data-cy='dashboardButton'>
          <ListItemIcon data-cy='dashboardIcon'>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary='Dashboard' data-cy='dashboardText' />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-cy='settingsListItem'>
        <ListItemButton data-cy='settingsButton'>
          <ListItemIcon data-cy='settingsIcon'>
            <SettingsIcon />
          </ListItemIcon>
          <ListItemText primary='Settings' data-cy='settingsText' />
        </ListItemButton>
      </ListItem>
    </div>
  );
};

export const SecondaryListItems = () => {
  return (
    <div>
      <ListSubheader inset data-cy='userReportsSubheader'>
        All User Reports
      </ListSubheader>
      <ListItem disablePadding data-cy='pendingPremiumListItem'>
        <ListItemButton data-cy='pendingPremiumButton'>
          <ListItemIcon data-cy='pendingPremiumIcon'>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText
            primary='Pending Premium'
            data-cy='pendingPremiumText'
          />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding data-cy='liveMinerViewListItem'>
        <ListItemButton data-cy='liveMinerViewButton'>
          <ListItemIcon data-cy='liveMinerViewIcon'>
            <AssignmentIcon />
          </ListItemIcon>
          <ListItemText primary='Live Miner View' data-cy='liveMinerViewText' />
        </ListItemButton>
      </ListItem>
    </div>
  );
};
