import React from "react";
import Team from "../components/Team";
// import ScrollToTop from "../components/ScrollToTop";
import Navbar from "../components/Navbar";
import { useState } from "react";
import Sidebar from "../components/Sidebar";
function Teampage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <div style={{ marginTop: "25px" }}>
        <Team />
      </div>
    </>
  );
}

export default Teampage;
