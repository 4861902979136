import { useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { DMPSUserData } from '../model/model';
import { api } from '../utils/enviornment';

export const useUpdateMetaData = () => {
  const [updating, setUpdating] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [updateError, setUpdateError] = useState<string | null>(null);

  const updateMetaData = async (id: string, updatedData: DMPSUserData) => {
    setUpdating(true);
    setUpdateError(null);
    setUpdateSuccess(false);

    try {
      const { tokens } = await fetchAuthSession();
      if (!tokens?.accessToken) {
        throw new Error('No access token available');
      }

      await axios.put(`${api}customer/updateMetaData/${id}`, updatedData, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.accessToken.toString()}`,
        },
      });

      setUpdateSuccess(true);
    } catch (error: any) {
      setUpdateError(
        error.message || 'An error occurred while updating metadata'
      );
    } finally {
      setUpdating(false);
    }
  };

  return { updateMetaData, updating, updateSuccess, updateError };
};
