import * as React from "react";
// import PropTypes from "prop-types";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import { Button, Grid } from "@mui/material";

export default function HostingInfo(props) {
  return (
    <React.Fragment>
      <CssBaseline />
      <Toolbar />
      <Grid
        columns={12}
        spacing={12}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{
          // border: "5px solid yellow",
          backgroundColor: "#5e636e",
          // backgroundImage: `url(${require("../../images/factory.jpg")})`,
          // height: "50vh",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid
          item
          xl={3}
          lg={3}
          // xs={12}
          justifyContent="center"
          alignItems="center"
        >
          <Typography
            variant="h3"
            // textAlign={"center"}
            color={"white"}
            sx={{
              fontSize: "48px",
              lineHeight: "3.7",
              fontFamily: "Playfair Display",
              color: "white",
            }}
          >
            Start Hosting Today
          </Typography>
          <Button
            variant="contained"
            color="success"
            fullWidth
            sx={{
              background: "white",
              marginBottom: "20px",
              color: "black",
              borderRadius: "25px",
              "&:hover": {
                color: "black",
                background: "lightgray",
              },
            }}
            href="/contact"
          >
            Inquire Now
          </Button>
        </Grid>
      </Grid>

      <Grid
        height={"53vh"}
        container
        direction="row"
        justifyContent="center"
        // sx={{ border: "5px solid yellow" }}
        alignItems="center"
      >
        <Grid item xs={12} md={4} xl={4} lg={4} sm={8}>
          <div>
            <div>
              <Typography
                variant="h3"
                sx={{
                  textAlign: "center",
                  color: "#6d6d6d",
                  fontWeight: "300",
                  fontSize: "20px",
                  marginBottom: "20px",
                  marginTop: "35px",
                  fontFamily:
                    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
                }}
              >
                Why with us
              </Typography>
            </div>

            <Typography
              variant="p"
              sx={{
                fontSize: "17px",
                lineHeight: "1.7",
                fontFamily:
                  "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
              }}
            >
              With our dedicated team of experts, we are able to provide you
              with the best hosting services in the industry. We are able to
              provide you with the best hosting services in the industry. With a
              dedicated team on site and 24/7 support, we are able to ensure
              downtime is never a problem as well as we are able ensure payout
              happen on a timely manner
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4} xl={4} lg={4} sm={8}>
          <div>
            <div>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#6d6d6d",
                  fontWeight: "300",
                  fontSize: "20px",
                  marginBottom: "20px",
                  marginTop: "35px",
                  fontFamily:
                    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
                }}
              >
                Why mine bitcoin
              </Typography>
            </div>

            <Typography
              sx={{
                fontSize: "17px",
                lineHeight: "1.7",
                fontFamily:
                  "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
              }}
            >
              With our prices of electricity beating the industry standard by a
              longshot and our transparent operations the oly question is
              weather or not it is financially worth it. Our analyst calculate
              that most of clients who are early adopters will be able to make
              back there initial investment withing the 12 - 18 months depending
              to the price of bitcoin.
            </Typography>
          </div>
        </Grid>
        <Grid item xs={12} md={4} xl={4} lg={4} sm={8}>
          <div>
            <div>
              <Typography
                sx={{
                  textAlign: "center",
                  color: "#6d6d6d",
                  fontWeight: "300",
                  fontSize: "20px",
                  marginBottom: "20px",
                  marginTop: "35px",
                  fontFamily:
                    "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
                }}
              >
                What to expect?
              </Typography>
            </div>

            <Typography
              sx={{
                fontSize: "17px",
                lineHeight: "1.7",
                fontFamily:
                  "-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Oxygen,Ubuntu,Cantarell,Fira Sans,Droid Sans,Helvetica Neue,sans-serif;",
              }}
            >
              After purchasing a miner from us, you can expect to get your
              miners online within 7-14 days (depending if you are sending a
              miner or are purchasing one through us). After configurations are
              made to have the your wallet information updated. You can expect
              cashout from your miner every 2 weeks (depending on the price of
              bitcoin).
            </Typography>
          </div>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
