import * as Yup from 'yup';

// Helper function for non-negative number validation
const nonNegativeNumber = (fieldName: string) =>
  Yup.number()
    .typeError(`${fieldName} must be a number`)
    .min(0, `${fieldName} cannot be negative`)
    .required(`${fieldName} is required`);

export const validationSchema = Yup.object().shape({
  given_name: Yup.string().defined('Given name is required'),
  family_name: Yup.string().defined('Family name is required'),
  email: Yup.string().email('Invalid email').defined('Email is required'),
  amount_paid: nonNegativeNumber('Amount paid'),
  status: Yup.string().defined('Status is required'),
  amount_owed: nonNegativeNumber('Amount owed'),
  number_of_mining_machines: Yup.number()
    .typeError('Number of mining machines must be a number')
    .integer('Number of mining machines must be an integer')
    .min(0, 'Number of mining machines cannot be negative')
    .required('Number of mining machines is required'),
});
