import React from 'react';
import // FaFacebook,
// FaInstagram,
// FaYoutube,
// FaTwitter,
// FaLinkedin
'react-icons/fa';
import {
  FooterContainer,
  FooterWrap,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  SocialMedia,
  SocialMediaWrap,
  SocialLogo,
  WebsiteRights,
  // SocialIcons,
  // SocialIconLink
} from './FooterElements';

// import Term from "./Terms.pdf";
const Footer = () => {
  return (
    <FooterContainer>
      <FooterWrap>
        <FooterLinksContainer>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>About Us</FooterLinkTitle>
              <FooterLink to='/about'>Team</FooterLink>
              {/* <FooterLink to='/sign-up'>How it works</FooterLink> */}
              {/* <FooterLink to="/">Testimonials</FooterLink> */}
              {/* <FooterLink to="/">Investors</FooterLink> */}
              {/* <FooterLink onClick={() => window.open(Term)}> */}
              Terms of Service
              {/* </FooterLink> */}
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Contact Us</FooterLinkTitle>
              <FooterLink to='/contact'>Contact</FooterLink>
              {/* <FooterLink to="/">Support</FooterLink>
              <FooterLink to="/">Destinations</FooterLink>
              <FooterLink to="/">Sponsorships</FooterLink> */}
            </FooterLinkItems>
          </FooterLinksWrapper>
          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>Videos</FooterLinkTitle>
              {/* <FooterLink to='/'>Footage of the facility</FooterLink> */}
              <FooterLink to='/'>Coming Soon</FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>Social Media</FooterLinkTitle>
              <FooterLink
                onClick={() => window.open('https://discord.gg/GXfPSCZRCU')}
              >
                Discord
              </FooterLink>
              <FooterLink
                onClick={() => window.open('https://t.me/dmpsmining')}
              >
                Telegram
              </FooterLink>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <SocialMedia>
          <SocialMediaWrap>
            <SocialLogo to='/'>DMPS Mining</SocialLogo>
            <WebsiteRights>
              DMPS Mining © 2020 All rights reserved.
            </WebsiteRights>
          </SocialMediaWrap>
        </SocialMedia>
      </FooterWrap>
    </FooterContainer>
  );
};

export default Footer;
