import React from "react";
import "./accessDenied.css";

const AccessDenied: React.FC = () => {
  return (
    <div className="access-denied-container">
      <div className="access-denied-card">
        <h1 className="access-denied-title">Access Denied</h1>
        <p className="access-denied-message">
          You don't have permission to view this page.
        </p>
        <button
          className="access-denied-back-button"
          onClick={() => window.history.back()}
        >
          Go Back
        </button>
      </div>
    </div>
  );
};

export default AccessDenied;
