import React from "react";
import StoreCard from "./StoreCard/StoreCard";

export default function StoreItems({ addToCart, Total, setTotal }) {
  return (
    <div>
      <StoreCard addToCart={addToCart} Total={Total} setTotal={setTotal} />
    </div>
  );
}
