import * as React from 'react';
import Typography from '@mui/material/Typography';
import Title from './Title';
import CustomLoadingIcon from '../../utils/customLoadingIcon';

let today = new Date();

export default function Deposits(props: any) {
  const { data, loading } = props;

  if (loading) {
    return <CustomLoadingIcon />;
  }

  return (
    <React.Fragment>
      <Title>BTC Mined</Title>
      <Typography
        data-cy={`btcBalance-${data.btc_balance}`}
        component='p'
        variant='h4'
      >
        {data.btc_balance + ' BTC'}
      </Typography>
      <Title>Cash Deposited</Title>
      <Typography
        component='p'
        variant='h4'
        data-cy={`amount_owed-${data.amount_owed}`}
      >
        {'$ ' + data.amount_owed}
      </Typography>
      <Title>Number of Miners Active</Title>
      <Typography
        component='p'
        variant='h4'
        data-cy={`number_of_mining_machines-${data.number_of_mining_machines}`}
      >
        {data.number_of_mining_machines + ' MH/s'}
      </Typography>

      <Typography color='text.secondary' sx={{ flex: 1 }}>
        Balance as of {today.toLocaleDateString()}
      </Typography>
    </React.Fragment>
  );
}
