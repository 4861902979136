import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { mainListItems, secondaryListItems } from './listItems';
import Chart from './Chart';
import Deposits from './Deposits';
import Orders from './Orders';
import Button from '@mui/material/Button';
import Alert from '@mui/material/Alert';
import { useCustomerMetaData } from '../../hooks/getUserMetadataHook';
import { signOut } from 'aws-amplify/auth';

const drawerWidth = 240;

const handleLogout = async () => {
  try {
    await signOut();
    console.log('Signed out');
    window.location.href = '/';
  } catch (error) {}
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: { theme: any; open: boolean }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const StyledLogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  fontWeight: 'bold',
  padding: theme.spacing(1),
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

export default function Dashboard() {
  const { records, loading, error } = useCustomerMetaData();

  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <>
      <ThemeProvider theme={mdTheme}>
        <Box sx={{ display: 'flex' }} data-cy='premium-dashboard-container'>
          <CssBaseline />
          <AppBar
            position='absolute'
            open={open}
            theme={mdTheme}
            data-cy='premium-app-bar'
          >
            <Toolbar
              sx={{
                pr: '24px',
              }}
              data-cy='premium-toolbar'
            >
              <IconButton
                edge='start'
                color='inherit'
                aria-label='open drawer'
                onClick={toggleDrawer}
                sx={{
                  marginRight: '36px',
                  ...(open && { display: 'none' }),
                }}
                data-cy='premium-menu-toggle'
              >
                <MenuIcon />
              </IconButton>
              <Typography
                component='h1'
                variant='h6'
                color='inherit'
                noWrap
                sx={{ flexGrow: 1 }}
                data-cy='premium-dashboard-title'
              ></Typography>

              <StyledLogoutButton
                data-cy='premium-logout-button'
                onClick={handleLogout}
                variant='contained'
              >
                Logout
              </StyledLogoutButton>
            </Toolbar>
          </AppBar>
          <Drawer
            variant='permanent'
            open={open}
            data-cy='premium-sidebar-drawer'
          >
            <Toolbar
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                px: [1],
                textAlign: 'center',
                border: '1px solid black',
              }}
              data-cy='premium-drawer-toolbar'
            >
              <IconButton
                onClick={toggleDrawer}
                data-cy='premium-drawer-toggle'
              >
                <ChevronLeftIcon />
              </IconButton>
            </Toolbar>
            <Divider />
            <List data-cy='premium-main-list-items'>{mainListItems}</List>
            <Divider />
            <List data-cy='premium-secondary-list-items'>
              {secondaryListItems}
            </List>
          </Drawer>
          <Box
            component='main'
            sx={{
              flexGrow: 1,
              height: '100vh',
              overflow: 'auto',
            }}
            data-cy='premium-main-content'
          >
            <Toolbar />
            <Container
              maxWidth='lg'
              sx={{ mt: 4, mb: 4 }}
              data-cy='premium-dashboard-content'
            >
              <Grid container spacing={3}>
                <Grid item xs={12} md={8} lg={9}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                    }}
                    data-cy='premium-chart-container'
                  >
                    <Chart data-cy='premium-chart' />
                  </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                  <Paper
                    sx={{
                      p: 2,
                      display: 'flex',
                      flexDirection: 'column',
                      height: 300,
                    }}
                    data-cy='premium-deposits-container'
                  >
                    <Deposits
                      data={records}
                      loading={loading}
                      data-cy='premium-deposits-component'
                    />
                  </Paper>
                </Grid>

                <Grid item xs={12}>
                  <Paper
                    sx={{ p: 2, display: 'flex', flexDirection: 'column' }}
                    data-cy='premium-orders-container'
                  >
                    <Orders data-cy='premium-orders-component' />
                  </Paper>
                </Grid>
              </Grid>
            </Container>
            {error ? (
              <Alert severity='error' data-cy='premium-error-alert'>
                An error occurred while fetching records
              </Alert>
            ) : null}
          </Box>
        </Box>
      </ThemeProvider>
    </>
  );
}
