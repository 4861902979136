import Button from '@mui/material/Button';
import Icon1 from '../../images/Bitcoinlogo/bitcoincolor.svg';
import Icon2 from '../../images/Bitcoinlogo/bitcoincolor1.svg';
import Icon3 from '../../images/Bitcoinlogo/bitcoincolor2.svg';
import Icon4 from '../../images/Bitcoinlogo/bitcoincolor4.svg';
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
  ServicesCard,
  ServicesIcon,
  ServicesH2,
  ServicesP,
  ServicesSubheader,
} from './ServicesElements';

const Services = () => {
  return (
    <ServicesContainer id='services' data-cy='services-container'>
      <ServicesH1 data-cy='cloud-mining-contracts'>
        Cloud Mining Contracts
      </ServicesH1>
      <ServicesSubheader data-cy='cloud-mining-contracts-sub'>
        A Cloud Mining contract is paying to rent mining power otherwise known
        as Hashrate. When you purchase a Cloud Mining Contract, we direct that
        mining power from our machines to mine Bitcoin directly into your
        private wallet.
      </ServicesSubheader>
      <ServicesWrapper data-cy='services-wrapper'>
        <ServicesCard data-cy='cloud-mining-card-1'>
          <ServicesIcon src={Icon1} data-cy='silver-tier-icon' />
          <ServicesH2 data-cy='silver-tier-title'>Silver Tier</ServicesH2>
          <ServicesP data-cy='silver-tier-hashrate'>10TH/s </ServicesP>
          <Button
            variant='contained'
            color='success'
            sx={{ marginTop: '10px', textDecoration: 'none' }}
            to='/payment'
            href='/payment'
            data-cy='silver-tier-purchase-button'
          >
            Purchase Package
          </Button>
        </ServicesCard>
        <ServicesCard data-cy='cloud-mining-card-2'>
          <ServicesIcon src={Icon2} data-cy='gold-tier-icon' />
          <ServicesH2 data-cy='gold-tier-title'>Gold Tier</ServicesH2>
          <ServicesP data-cy='gold-tier-hashrate'>50TH/s </ServicesP>
          <Button
            variant='contained'
            color='success'
            href='/payment'
            sx={{ marginTop: '10px', textDecoration: 'none' }}
            data-cy='gold-tier-purchase-button'
          >
            Purchase Package
          </Button>
        </ServicesCard>
        <ServicesCard data-cy='cloud-mining-card-3'>
          <ServicesIcon src={Icon3} data-cy='platinum-tier-icon' />
          <ServicesH2 data-cy='platinum-tier-title'>Platinum tier</ServicesH2>
          <ServicesP data-cy='platinum-tier-hashrate'>104TH/s</ServicesP>
          <Button
            variant='contained'
            color='success'
            href='/payment'
            sx={{ marginTop: '10px', textDecoration: 'none' }}
            data-cy='platinum-tier-purchase-button'
          >
            Purchase Package
          </Button>
        </ServicesCard>
        <ServicesCard data-cy='cloud-mining-card-4'>
          <ServicesIcon src={Icon4} data-cy='custom-package-icon' />
          <ServicesH2 data-cy='custom-package-title'>Custom Package</ServicesH2>
          <Button
            variant='contained'
            color='success'
            sx={{ marginTop: '30px', textDecoration: 'none' }}
            href='/payment'
            data-cy='custom-package-purchase-button'
          >
            Purchase Package
          </Button>
        </ServicesCard>
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default Services;
