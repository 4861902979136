export const data = [
  {
    id: 1,
    title: "Miner 1",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/1",
    price: 5000,
  },
  {
    id: 2,
    title: "Miner 2",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/2",
    price: 4600,
  },
  {
    id: 3,
    title: "Miner 3",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/10",
    price: 9040,
  },
  {
    id: 4,
    title: "Miner 4",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/4",
    price: 3300,
  },
  {
    id: 5,
    title: "Miner 5",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/11",
    price: 8050,
  },
  {
    id: 6,
    title: "Miner 6",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/6",
    price: 3000,
  },
  {
    id: 7,
    title: "Miner 7",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/7",
    price: 8900,
  },
  {
    id: 8,
    title: "Miner 8",
    content: `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ac aliquam nunc. 
    Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas.`,
    image: "https://source.unsplash.com/random/8",
    price: 9370,
  },
];
