import React from "react";
import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
} from "./ServicesElements";
import Cardinfo from "./Card";
import { hostingObjThree, hostingObjOne, hostingObjTwo } from "./Data";

const PurchaseHardware = () => {
  return (
    <ServicesContainer id="hardware">
      <ServicesH1>Purchase Hardware</ServicesH1>
      <ServicesWrapper>
        <Cardinfo {...hostingObjOne} />
        <Cardinfo {...hostingObjTwo} />
        <Cardinfo {...hostingObjThree} />
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default PurchaseHardware;
