import React, { useState, useEffect } from 'react';

import { Formik, Form, Field } from 'formik';
import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Toolbar,
  TextField,
  InputAdornment,
  Tooltip,
  Typography,
  MenuItem,
  Select,
  DialogContent,
  FormControl,
  InputLabel,
  DialogActions,
  Button,
  Dialog,
  DialogTitle,
  Alert,
  Snackbar,
  styled,
} from '@mui/material';
import {
  Search,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '@mui/icons-material';
import TablePaginationActions from '@mui/material/TablePagination/TablePaginationActions';
import { DMPSUserData, UserRole } from '../../../model/model';
import { useUpdateMetaData } from '../../../hooks/updateUserMetadataHook';
import { useDeleteMetaData } from '../../../hooks/deleteUserMetadataHook';
import { useAllCustomerMetaData } from '../../../hooks/getAllUserMetadataHook';
import CustomLoadingIcon from '../../../utils/customLoadingIcon';
import { validationSchema } from '../validation';

function CustomerAdminTable() {
  const [searchTerm, setSearchTerm] = useState('');
  const [selected, setSelected] = useState<number[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [editingUser, setEditingUser] = useState<DMPSUserData | null>(null);
  const [loadingError, setLoadingError] = useState<string | null>(null);
  const [savingError, setSavingError] = useState<string | null>(null);

  const [saveSuccessful, setSaveSuccessful] = useState(false);

  const { records, isLoading, error, refetch } = useAllCustomerMetaData();
  const { updateMetaData } = useUpdateMetaData();
  const { deleteMetaData, deleteSuccess, deleteError } = useDeleteMetaData();

  useEffect(() => {
    if (error) {
      setLoadingError('Error loading users. Please try again later.');
    }
  }, [error]);

  useEffect(() => {
    if (saveSuccessful) {
      setEditDialogOpen(false);
      setSaveSuccessful(false);
      refetch();
    }
  }, [saveSuccessful, refetch]);

  const openEditDialog = (user: DMPSUserData) => {
    setEditingUser(user);
    setEditDialogOpen(true);
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const handleSelectAllClick = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      const newSelecteds = records.map((n, index) => index);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, number: number) => {
    const selectedIndex = selected.indexOf(number);
    let newSelected: number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, number);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (number: number) => selected.indexOf(number) !== -1;

  const handleChangePage = (_: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const deleteUser = async (id: string) => {
    await deleteMetaData(id);
    if (deleteSuccess) {
      await refetch();
    } else if (deleteError) {
      setSavingError('Error deleting user. Please try again.');
    }
  };

  const deleteSelectedUsers = async () => {
    const success = await Promise.all(
      selected.map((index) => deleteUser(records[index].id))
    );
    if (success.every((value) => value)) {
      setSelected([]);
      await refetch();
    } else {
      setSavingError('Error deleting users. Please try again.');
    }
  };

  const handleSave = async (
    values: DMPSUserData,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    console.log('Save button clicked');
    if (editingUser) {
      const updatedUser = { ...editingUser, ...values };
      console.log('Updating user:', updatedUser);
      try {
        await updateMetaData(updatedUser.id, updatedUser);
        console.log('Update successful');
        setEditingUser(null);
        setSaveSuccessful(true);
        setEditDialogOpen(false);
        console.log('Dialog closed');
      } catch (error) {
        console.error('Error updating user:', error);
        setSavingError('Error saving user. Please try again.');
      }
    }
    setSubmitting(false);
  };

  const handleCloseAlert = (type: 'loading' | 'saving') => {
    if (type === 'loading') {
      setLoadingError(null);
    } else {
      setSavingError(null);
    }
  };

  const filteredRecords = records.filter((record) => {
    return Object.values(record).some((value) =>
      value.toString().toLowerCase().includes(searchTerm.toLowerCase())
    );
  });
  const ReadOnlyTextField = styled(TextField)(({ theme }) => ({
    '& .MuiInputBase-input.Mui-readOnly': {
      backgroundColor: theme.palette.action.disabledBackground,
      borderRadius: theme.shape.borderRadius,
    },
  }));

  return (
    <Paper sx={{ width: '100%', mb: 2 }}>
      <Toolbar>
        <Typography
          data-cy='AdminDashboardTitle'
          variant='h6'
          component='div'
          sx={{ flexGrow: 1 }}
        >
          Users
        </Typography>

        <Tooltip title='Delete'>
          <IconButton
            onClick={deleteSelectedUsers}
            disabled={selected.length === 0}
            data-cy='deleteUsersIconButton'
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
        <TextField
          variant='outlined'
          size='small'
          value={searchTerm}
          onChange={handleSearchChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}
          placeholder='Search users'
          data-cy='searchUsers'
        />
      </Toolbar>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox
                  color='primary'
                  indeterminate={
                    selected.length > 0 && selected.length < records.length
                  }
                  checked={
                    records.length > 0 && selected.length === records.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    'aria-label': 'select all users',
                  }}
                />
              </TableCell>
              <TableCell align='right'>Given Name</TableCell>
              <TableCell align='right'>Family Name</TableCell>
              <TableCell align='right'>Email</TableCell>
              <TableCell align='right'>Amount Paid</TableCell>
              <TableCell align='right'>Status</TableCell>
              <TableCell align='right'>Amount Owed</TableCell>
              <TableCell align='right'>Number of Mining Machines</TableCell>
              <TableCell align='right'>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={9} align='center'>
                  <CustomLoadingIcon />
                </TableCell>
              </TableRow>
            ) : (
              filteredRecords
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(index);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const userIdentifier = `${row.email.toLowerCase()}`.replace(
                    /\s+/g,
                    '-'
                  );

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, index)}
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.given_name + row.family_name + row.email + index}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          color='primary'
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        data-cy={`given_name${userIdentifier}`}
                        align='right'
                      >
                        {row.given_name}
                      </TableCell>
                      <TableCell
                        data-cy={`family_name${userIdentifier}`}
                        align='right'
                      >
                        {row.family_name}
                      </TableCell>
                      <TableCell
                        data-cy={`email${userIdentifier}`}
                        align='right'
                      >
                        {row.email}
                      </TableCell>
                      <TableCell
                        data-cy={`amountPaid${userIdentifier}`}
                        align='right'
                      >
                        {row.amount_paid}
                      </TableCell>
                      <TableCell
                        data-cy={`status${userIdentifier}`}
                        align='right'
                      >
                        {row.status}
                      </TableCell>
                      <TableCell
                        data-cy={`amount_owed${userIdentifier}`}
                        align='right'
                      >
                        {row.amount_owed}
                      </TableCell>
                      <TableCell
                        data-cy={`number_of_mining_machines${userIdentifier}`}
                        align='right'
                      >
                        {row.number_of_mining_machines}
                      </TableCell>
                      <TableCell align='right'>
                        <IconButton
                          data-cy={`editUser-${userIdentifier}`}
                          onClick={(event) => {
                            event.stopPropagation();
                            openEditDialog(row);
                          }}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                })
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component='div'
        count={filteredRecords.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
        data-cy='tablePagination'
      />

      <Dialog
        open={editDialogOpen}
        onClose={() => {
          setEditDialogOpen(false);
          setEditingUser(null);
        }}
        aria-labelledby='form-dialog-title'
      >
        <DialogTitle id='form-dialog-title'>Edit User</DialogTitle>
        <Formik
          initialValues={editingUser as DMPSUserData}
          validationSchema={validationSchema}
          onSubmit={handleSave}
          enableReinitialize
        >
          {({ errors, touched, isSubmitting }) => (
            <Form>
              <DialogContent>
                <Field
                  as={TextField}
                  autoFocus
                  margin='dense'
                  id='given_name'
                  name='given_name'
                  label='Given Name'
                  fullWidth
                  data-cy='given_name'
                  error={touched.given_name && errors.given_name}
                  helperText={touched.given_name && errors.given_name}
                />
                <Field
                  as={TextField}
                  margin='dense'
                  id='family_name'
                  name='family_name'
                  label='Family Name'
                  fullWidth
                  data-cy='family_name'
                  error={touched.family_name && errors.family_name}
                  helperText={touched.family_name && errors.family_name}
                />
                <Field
                  as={ReadOnlyTextField}
                  margin='dense'
                  id='email'
                  name='email'
                  label='Email'
                  fullWidth
                  data-cy='emailUpdate'
                  error={touched.email && errors.email}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    readOnly: true,
                  }}
                />
                <Field
                  as={TextField}
                  fullWidth
                  id='amount_paid'
                  name='amount_paid'
                  label='Amount Paid'
                  type='number'
                  error={touched.amount_paid && errors.amount_paid}
                  helperText={touched.amount_paid && errors.amount_paid}
                  data-cy='amountPaid'
                />
                <FormControl fullWidth margin='dense'>
                  <InputLabel>Status</InputLabel>
                  <Field
                    as={Select}
                    name='status'
                    label='Status'
                    data-cy='userStatus'
                  >
                    <MenuItem data-cy='userAdmin' value={UserRole.Admin}>
                      Admin
                    </MenuItem>
                    <MenuItem data-cy='userGuest' value={UserRole.Guest}>
                      Guest
                    </MenuItem>
                    <MenuItem data-cy='userPremium' value={UserRole.Premium}>
                      Premium
                    </MenuItem>
                  </Field>
                </FormControl>
                <Field
                  as={TextField}
                  fullWidth
                  id='amount_owed'
                  name='amount_owed'
                  label='Amount Owed'
                  type='number'
                  data-cy='amount_owed'
                  error={touched.amount_owed && errors.amount_owed}
                  helperText={touched.amount_owed && errors.amount_owed}
                />
                <Field
                  as={TextField}
                  fullWidth
                  id='number_of_mining_machines'
                  name='number_of_mining_machines'
                  label='Number of Mining Machines'
                  type='number'
                  data-cy='number_of_mining_machines'
                  error={
                    touched.number_of_mining_machines &&
                    errors.number_of_mining_machines
                  }
                  helperText={
                    touched.number_of_mining_machines &&
                    errors.number_of_mining_machines
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setEditDialogOpen(false)}>Cancel</Button>
                <Button
                  type='submit'
                  disabled={isSubmitting}
                  data-cy='submit-button'
                >
                  Save
                </Button>
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>

      <Snackbar
        open={!!loadingError}
        autoHideDuration={6000}
        onClose={() => handleCloseAlert('loading')}
      >
        <Alert
          onClose={() => handleCloseAlert('loading')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {loadingError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={!!savingError}
        autoHideDuration={6000}
        onClose={() => handleCloseAlert('saving')}
      >
        <Alert
          onClose={() => handleCloseAlert('saving')}
          severity='error'
          sx={{ width: '100%' }}
        >
          {savingError}
        </Alert>
      </Snackbar>
    </Paper>
  );
}

export default CustomerAdminTable;
