import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import TrendingFlatOutlinedIcon from "@mui/icons-material/TrendingFlatOutlined";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainFeaturedPost from "./MainFeaturedPost";
import FeaturedPost from "./FeaturedPost";
import Main from "./Main";
import Sidebar from "./Sidebar";
import Team from "./Team";
import { Typography } from "@mui/material";
import bitcoincard from "./data/images/bitcoincard.jpg";
import bitcoindollar from "./data/images/bitcoindollar.jpg";
import bitcoinsand from "./data/images/bitcoinsand.jpg";
// import bitcoincard from "./data/images/bitcoincard.jpg";

// const sections = [
//   { title: "Contact", url: "/contact" },
//   { title: "Team", url: "/team" },
//   { title: "Purchase Hashrate Packages", url: "/payment" },
//   { title: "Buy Miners", url: "/hosting" },
// ];

const mainFeaturedPost = {
  title: "Learn about Crypto Mining",
  description:
    "DMPS is building the future of finance. Blockchain is the most trusted and fastest growing crypto company, helping millions across the globe – from single individuals to the largest institutions – have an easy and safe way to access cryptocurrency",
  image: bitcoincard,
  imageText: "main image description",
  // linkText: "Continue reading…",
};

const featuredPosts = [
  {
    title: "How Hosting miners Works",
    date: "May 12",
    description:
      "Hosting or sometimes also referred to as colocation, is the ability to operate your ASIC miners or GPU mining rigs in a dedicating mining facility. These facilities are often secure data centres which have been optimised for miners.",
    image: bitcoinsand,
    imageLabel: "main image description",
  },
  {
    title: "How Cloud mining works",
    date: "Nov 11",
    description:
      "Cloud mining involves mining for cryptocurrencies by leasing or purchasing mining equipment from a third-party cloud provider, who is responsible for maintaining the equipment.",
    image: bitcoindollar,
    imageLabel: "Image Text",
  },
];
const post1 = `#Web 3 the future 

March 1, 2021 by [Derek Poon](/)

Our team at DMPS Mining believe that web3 integration will see mass adoption within the next few years and our goal is to contribute it anyway we can.  Bitcoin Mining is one of the easiest ways to contribute to the future of 
blockchain technology. By mining bitcoin we are contributing to the security and decentralized growth of Bitcoin. Because we are a small company we can provide support at a much more personal and detailed level to ensure our 
customers have the best mining experience as possible and we aim to keep this level of support as we grow and expand as a company.`;

// const post2 = `##Dapibus turpis, id finibus la

// Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum imperdiet lectus, nec pharetra neque hendrerit ut. Vivamus varius, elit eu cursus vulputate, neque neque mollis lectus, eu facilisis lacus dui vitae quam. Cras porta dapibus turpis, id finibus lacus tristique eget. Nunc vitae purus libero. Integer condimentum dignissim ante,

//   eu dapibus elit malesuada sit amet. Cras lacinia, felis ut luctus semper, eros mi placerat leo, ut posuere metus nisi fermentum elit. Mauris venenatis ligula sem, nec cursus erat consectetur sed. Donec convallis tellus mi, ac suscipit ex porta ut. Quisque ullamcorper enim nisl, vitae fringilla mauris iaculis sed. Cras eget sagittis ex.

//   Maecenas dictum consectetur nibh, eu tristique elit iaculis sit amet. Vestibulum feugiat leo leo, id sagittis ipsum porttitor in. Fusce efficitur iaculis nulla vel facilisis.`;
// const post3 = `##Psdf, elit eu cursus vulputate, neque ne

// Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec rutrum imperdiet lectus, nec pharetra neque hendrerit ut. Vivamus varius, elit eu cursus vulputate, neque neque mollis lectus, eu facilisis lacus dui vitae quam. Cras porta dapibus turpis, id finibus lacus tristique eget. Nunc vitae purus libero.

//   Integer condimentum dignissim ante, eu dapibus elit malesuada sit amet. Cras lacinia, felis ut luctus semper, eros mi placerat leo, ut posuere metus nisi fermentum elit. Mauris venenatis ligula sem, nec cursus erat consectetur sed. Donec convallis tellus mi, ac suscipit ex porta ut. Quisque ullamcorper enim nisl,

//   vitae fringilla mauris iaculis sed. Cras eget sagittis ex. Maecenas dictum consectetur nibh, eu tristique elit iaculis sit amet. Vestibulum feugiat leo leo, id sagittis ipsum porttitor in. Fusce efficitur iaculis nulla vel facilisis.`;
const posts = [post1];

const sidebar = {
  title: "About",
  description:
    "DMPS seeks to bridge the gap between the world of cryptocurrency and the world of finance. We are a small company with a mission to provide the best possible service to our customers.",
  archives: [
    // { title: "March 2022", url: "#" },
    // { title: "February 2022", url: "#" },
    // { title: "January 2022", url: "#" },
    // { title: "November 2022", url: "#" },
    // { title: "October 2022", url: "#" },
    // { title: "September 2022", url: "#" },
    // { title: "August 2022", url: "#" },
    // { title: "July 2022", url: "#" },
    // { title: "June 2022", url: "#" },
    // { title: "May 2022", url: "#" },
    // { title: "April 2022", url: "#" },
  ],
  social: [
    { name: "WhatsApp", icon: TrendingFlatOutlinedIcon },
    { name: "Discord", icon: TrendingFlatOutlinedIcon },
    // { name: "Other", icon: TrendingFlatOutlinedIcon },
  ],
};

const theme = createTheme();

export default function Blog() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ marginTop: "100px" }}>
        {/* <Header title="About" sections={sections} /> */}
        <main>
          <MainFeaturedPost post={mainFeaturedPost} />
          <Grid container spacing={4}>
            {featuredPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
          <Grid container spacing={5} sx={{ mt: 3 }}>
            <Main title="From the team" posts={posts} />
            <Sidebar
              title={sidebar.title}
              description={sidebar.description}
              archives={sidebar.archives}
              social={sidebar.social}
            />
          </Grid>
          <Typography variant="h3">Team members behind DMPS</Typography>
          <Team />
        </main>
      </Container>
    </ThemeProvider>
  );
}
