import React from "react";
import ContactUs from "../components/ContactUs";
import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import { useState } from "react";
import Sidebar from "../components/Sidebar";

function Contact() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <ScrollToTop />
      <div style={{ marginTop: "25px" }}>
        <ContactUs />
      </div>
    </>
  );
}

export default Contact;
