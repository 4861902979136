import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import store from './redux/store';
import { Provider } from 'react-redux';
import { App } from './App';
import { Amplify } from 'aws-amplify';
// import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito';

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID as string,
      userPoolClientId: process.env
        .REACT_APP_COGNITO_USER_POOL_CLIENT_ID as string,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN as string,
          scopes: ['openid', 'email', 'profile'],
          redirectSignIn: ['http://localhost:3000/'],
          redirectSignOut: ['http://localhost:3000/'],
          responseType: 'code',
        },
      },
    },
  },
});

// cognitoUserPoolsTokenProvider.setKeyValueStorage(window.sessionStorage);

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);
