import * as React from 'react'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActions from '@mui/material/CardActions'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { data } from './data/data'

export default function Team () {
  return (
    <Container sx={{ py: 8 }} maxWidth='xl'>
      <Grid container spacing={4}>
        {data.map((item, idx) => (
          <Grid item key={idx} xs={12} sm={6} md={4}>
            <Card
              sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
              <CardMedia
                component='img'
                sx={{
                  // 16:9
                  pt: '5.25%'
                }}
                image={item.image}
                alt='random'
              />
              <CardContent sx={{ flexGrow: 1 }}>
                <Typography gutterBottom variant='h5' component='h2'>
                  {item.title}
                </Typography>
                <Typography>{item.content}</Typography>
              </CardContent>
              <CardActions>
                {item.Contact ? (
                  <Button
                    size='small'
                    onClick={() => {
                      window.open(
                        'https://www.linkedin.com/in/derek-poon-76a286aa/'
                      )
                    }}
                  >
                    Contact
                  </Button>
                ) : null}
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}
