import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './pages';
import instructions from './pages/instructions';
import login from './pages/login';
import Teampage from './pages/Teampage';
import DashboardPage from './pages/Dashboard';
import GuestDashboardPage from './pages/GuestDashboardPage';
import AdminDashboardPage from './pages/AdminDashboard';
import NotFound from './components/PrivateRoute/NotFound';
import Host from './pages/Host';
import contact from './pages/contact';
import PaymentsPage from './pages/PaymentsPage';
import Confirmation from './components/Confirmation/Confirmation';
import SignIn from './components/CreateAccount/CreateAccount';
import SignUp from './components/CreateAccount/CreateAccount';
import Aboutpage from './pages/Aboutpage';
import MinerPage from './pages/MinerPage';
import StorePayment from './pages/StorePayment';
import ProtectedRoute from './utils/protectedRoutes';
import SignUpSuccess from './components/CreateAccount/PostSignUpForm';
import AccessDenied from './utils/accessDenied';

export function App() {
  return (
    <Router>
      <Switch>
        <Route path='/' component={Home} exact />
        <Route path='/checkout-store' component={StorePayment} exact />
        <Route path='/sign-in' component={SignIn} exact />
        <Route path='/about' component={Aboutpage} exact />
        <Route path='/sign-up' component={SignUp} exact />
        <Route path='/instructions' component={instructions} exact />
        <Route path='/payment' component={PaymentsPage} exact />
        <Route path='/login' component={login} exact />
        <Route path='/Contact' component={contact} exact />
        <Route path='/Team' component={Teampage} exact />
        <Route path='/Hosting' component={Host} exact />
        <Route path='/Store' component={MinerPage} exact />
        <Route path='/payment-confirmation' component={Confirmation} exact />
        <ProtectedRoute
          path='/premium-dashboard'
          component={DashboardPage}
          allowedGroups={['Premium']}
          exact
        />
        <ProtectedRoute
          path='/guest-dashboard'
          component={GuestDashboardPage}
          allowedGroups={['Guest']}
          exact
        />

        <ProtectedRoute
          path='/admin'
          component={AdminDashboardPage}
          allowedGroups={['Admin']}
        />

        <ProtectedRoute
          path='/post-sign-up'
          component={SignUpSuccess}
          allowedGroups={['Guest']}
          exact
        />
        <Route path='/access-denied' component={AccessDenied} />
        <Route path='*' component={NotFound} />
      </Switch>
    </Router>
  );
}
