import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import axios from 'axios';
export default function App() {
  const [show, setShow] = useState(false);
  const [showCC, setCC] = useState(false);
  const [showCrypto, setCrypto] = useState(false);
  const [success, setSuccess] = useState(false);

  // const [ErrorMessage, setErrorMessage] = useState("");
  const [orderID, setOrderID] = useState(false);
  // const clientID = process.env.APP_PAYPAL_CLIENT_ID;
  // console.log(clientID);
  const { count } = useSelector((state) => state.counter);
  const { email } = useSelector((state) => state.email);
  const { wallet } = useSelector((state) => state.wallet);
  const { fullName } = useSelector((state) => state.name);
  // creates a paypal order

  useEffect(() => {
    if (success) {
      alert('Payment successful!!');
    }
  }, [success]);

  console.log(1, orderID);
  console.log(2, success);
  // console.log(3, ErrorMessage);

  const createOrder = (data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: 'Miners',
            amount: {
              currency_code: 'USD',
              value: count,
            },
          },
        ],
        // not needed if a shipping address is actually needed
        application_context: {
          shipping_preference: 'NO_SHIPPING',
        },
      })
      .then((orderID) => {
        setOrderID(orderID);
        return orderID;
      });
  };

  // check Approval
  const onApprove = async (data, actions) => {
    return actions.order.capture().then(function (details) {
      // const { payer } = details;
      setSuccess(true);
      axios
        .post('/api/cloud', {
          FullName: fullName,
          Email: email,
          wallet: wallet,
          price: count,
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    });
    // todo redirect to payment success page
  };
  //capture likely error
  // const onError = (data, actions) => {
  //   setErrorMessage("An Error occured with your payment ");
  // };

  const Crypto = () => {
    setCrypto(true);
    setCC(false);
    setShow(false);
  };
  // const CreditCard = () => {
  //   setCrypto(false);
  //   setCC(true);
  //   setShow(false);
  // };
  const Paypal = () => {
    setCrypto(false);
    setCC(false);
    setShow(true);
  };
  return (
    <div>
      <PayPalScriptProvider
        options={{
          // "client-id":
          //   "Ae3yv9GhJV5E8J604RPtZ-gwECsxpdR6IAJxTqidrOkgfiiPP_froU7rXKqeuTRtDZ5jKiFO6cW7qPQ5",
          'client-id': process.env.REACT_APP_PAYPAL,
        }}
      >
        <div>
          <div className='wrapper'>
            <div className='product-info'>
              <div
                style={{
                  display: 'flex',
                  columnGap: '20px',
                  justifyContent: 'spaceBetween',
                }}
              >
                <Button variant='contained' type='submit' onClick={Paypal}>
                  Pay Via Paypal
                </Button>
                <Button type='submit' variant='contained' onClick={Paypal}>
                  Credit Card
                </Button>
                <Button onClick={Crypto} variant='contained'>
                  Crypto Transfer
                </Button>
              </div>
            </div>
          </div>
          <div style={{ overflow: 'hidden', marginTop: '10px' }}>
            {show ? (
              <PayPalButtons
                style={{ layout: 'vertical' }}
                createOrder={createOrder}
                onApprove={onApprove}
              />
            ) : null}
          </div>
          <div style={{ overflow: 'hidden', marginTop: '20px' }}>
            {showCC ? (
              <Typography component='h1' variant='h4' align='center'>
                Credit Card Payment Method coming soon!
              </Typography>
            ) : null}
          </div>
          <div style={{ overflow: 'hidden', marginTop: '20px' }}>
            {showCrypto ? (
              <Typography component='h1' variant='h4' align='center'>
                To pay via crypto, please send $500 CAD equivalent of crypto to
                xx-xxxx-xxxx-xxx wallet address. After send us an email with a
                screenshot of the crypto payment along with your name, package
                choice, and bitcoin wallet
              </Typography>
            ) : null}
          </div>
        </div>
      </PayPalScriptProvider>
    </div>
  );
}
