import React, { useState } from 'react';
import { Button } from '../ButtonElements';

import Video from '../../videos/video.mp4';
import {
  HeroContainer,
  HeroBg,
  // VideoBg,
  HeroContent,
  HeroH1,
  HeroP,
  HeroBtnWrapper,
  ArrowForward,
  ArrowRight,
} from './HeroElements';

function HeroSection() {
  const [hover, setHover] = useState(false);

  const onHover = () => {
    setHover(!hover);
  };
  return (
    <HeroContainer id='home' data-cy='hero-container'>
      <HeroBg data-cy='hero-background'>
        <video
          autoplay='autoplay'
          loop='loop'
          muted
          defaultMuted
          playsinline
          oncontextmenu='return false;'
          preload='auto'
          id='myVideo'
          data-cy='hero-video'
        >
          <source src={Video} type='video/mp4' data-cy='hero-video-source' />
        </video>
      </HeroBg>
      <HeroContent data-cy='hero-content'>
        <HeroH1 data-cy='main-title'>Crypto Mining Canada</HeroH1>
        <HeroP data-cy='second-title'>
          We are a Canada based Crypto company that specializes in Bitcoin
          Mining. We provide ASIC Hosting services, supply ASIC Miners and have
          the options of Cloud Mining contracts.
        </HeroP>
        <HeroBtnWrapper data-cy='hero-button-wrapper'>
          <Button
            to='signup'
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            primary='true'
            dark='true'
            onMouseEnter={onHover}
            onMouseLeave={onHover}
            data-cy='get-started-button'
          >
            Get Started{' '}
            {hover ? (
              <ArrowForward data-cy='arrow-forward' />
            ) : (
              <ArrowRight data-cy='arrow-right' />
            )}
          </Button>
        </HeroBtnWrapper>
      </HeroContent>
    </HeroContainer>
  );
}

export default HeroSection;
