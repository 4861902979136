import {
  ServicesContainer,
  ServicesH1,
  ServicesWrapper,
} from './ServicesElements';
import Cardinfo from './Card';
import { hostingObjThree, hostingObjOne, hostingObjTwo } from './Data';

const HostingService = () => {
  return (
    <ServicesContainer id='hosting' data-cy='hosting-services-container'>
      <ServicesH1 data-cy='hosting-services-title'>Hosting Services</ServicesH1>
      <ServicesWrapper data-cy='hosting-services-wrapper'>
        <Cardinfo {...hostingObjOne} data-cy='hosting-card-one' />
        <Cardinfo {...hostingObjTwo} data-cy='hosting-card-two' />
        <Cardinfo {...hostingObjThree} data-cy='hosting-card-three' />
      </ServicesWrapper>
    </ServicesContainer>
  );
};

export default HostingService;
