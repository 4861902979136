import React from 'react';
import GuestDashboard from '../components/GuestDashboard';

function GuestDashboardPage() {
  return (
    <>
      <GuestDashboard />
    </>
  );
}

export default GuestDashboardPage;
