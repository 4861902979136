import * as React from "react";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { useSelector } from "react-redux";

export default function Review() {
  const { count } = useSelector((state) => state.counter);
  const { tier } = useSelector((state) => state.counter);
  const { hashrate } = useSelector((state) => state.counter);
  // const { email } = useSelector((state) => state.email);
  // const { wallet } = useSelector((state) => state.wallet);
  // const { fullName } = useSelector((state) => state.name);

  const products = [
    {
      name: tier,
      desc: hashrate,
      price: `$${count}`,
    },
    // {
    //   name: "Name",
    //   desc: fullName,
    // },
    // {
    //   name: "Wallet Address",
    //   desc: wallet,
    // },
    // {
    //   name: "Email Address",
    //   desc: email,
    // },
    { name: "Shipping", desc: "", price: "Free" },
    { name: "Tax/Vat", desc: "", price: "Free" },
  ];

  // const addresses = ["3 Yeoman Street", "London", "CA", "SE8 5DF", "GB"];
  // const payments = [
  //   { name: "Card type", detail: "Visa" },
  //   { name: "Card holder", detail: "Samuel Lawrence" },
  //   { name: "Card number", detail: "xxxx-xxxx-xxxx-4242" },
  //   { name: "Expiry date", detail: "04/2024" },
  // ];

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Order summary
      </Typography>
      <List disablePadding>
        {products.map((product) => (
          <ListItem key={product.name} sx={{ py: 1, px: 0 }}>
            <ListItemText primary={product.name} secondary={product.desc} />
            <Typography variant="body2">{product.price}</Typography>
          </ListItem>
        ))}

        <ListItem sx={{ py: 1, px: 0 }}>
          <ListItemText primary="Total" />
          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
            {"$" + count}
          </Typography>
        </ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          {/* <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Address
          </Typography> */}
          {/* <Typography gutterBottom>{fullname}</Typography> */}
          {/* <Typography gutterBottom>{email}</Typography> */}
          {/* <Typography gutterBottom>{addresses.join(", ")}</Typography> */}
          {/* <Typography gutterBottom>{wallet}</Typography> */}
        </Grid>
        <Grid item container direction="column" xs={12} sm={6}>
          {/* <Typography variant="h6" gutterBottom sx={{ mt: 2 }}>
            Payment details
          </Typography> */}
          <Grid container>
            {/* {payments.map((payment) => (
              <React.Fragment key={payment.name}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{payment.detail}</Typography>
                </Grid>
              </React.Fragment>
            ))} */}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
