import * as React from "react";
import Grid from "@mui/material/Grid";
// import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { PackageTwo, PackageOne, PackageThree } from "../../redux/counter";
import { setEmail } from "../../redux/email";
import { setFullName } from "../../redux/name";
import { setWalletAddress } from "../../redux/wallet";
// import { useSelector } from "react-redux";
export default function AddressForm() {
  const [miningPackage, setPackage] = React.useState("");

  const dispatch = useDispatch();
  // const { email } = useSelector((state) => state.email);
  // const [myValue, setValue] = useState("");

  const handleChange = (event) => {
    setPackage(event.target.value);
  };
  const handleChangeEmail = (event) => {
    event.preventDefault();
    dispatch(setEmail(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeWallet = (event) => {
    event.preventDefault();
    dispatch(setWalletAddress(event.target.value));
    console.log(event.target.value);
  };
  const handleChangeName = (event) => {
    event.preventDefault();
    dispatch(setFullName(event.target.value));
    console.log(event.target.value);
  };
  return (
    <React.Fragment>
      <div style={{ lineHeight: "4.5" }}>
        <FormControl fullWidth>
          <InputLabel fullWidth>Package</InputLabel>
          <Select
            fullWidth
            value={miningPackage}
            onChange={handleChange}
            autoWidth
            label="package"
          >
            <MenuItem
              fullWidth
              value={"Silver"}
              onClick={() => dispatch(PackageOne())}
            >
              Silver - $500
            </MenuItem>
            <MenuItem
              fullWidth
              value={"Gold"}
              onClick={() => dispatch(PackageTwo())}
            >
              Gold - $700
            </MenuItem>
            <MenuItem
              fullWidth
              value={"Platinum"}
              onClick={() => dispatch(PackageThree())}
            >
              Platinum - $1000
            </MenuItem>
          </Select>
        </FormControl>
        <Grid item xs={12}>
          <TextField
            // onClick={() => dispatch(setEmail("email"))}
            // onChange={(e) => console.log(e.target.value)}
            onChange={handleChangeEmail}
            // onChange={(e) => setValue(e.target.value)}
            fullWidth
            required
            label="Email"
            variant="standard"
            // value={email}
          />
          <TextField
            onChange={handleChangeName}
            fullWidth
            required
            label="Full Name"
            variant="standard"
            // value={email}
          />
          <TextField
            onChange={handleChangeWallet}
            fullWidth
            required
            label="Wallet address"
            variant="standard"
            // value={email}
          />
        </Grid>
      </div>
      <Grid container spacing={3} component="form">
        <Grid item xs={12}>
          After purchasing the package, you can expect an email from our team
          within 7 business days where we will send you the instruction for on
          boarding you onto our platform to start receiving your bitcoin.
        </Grid>
        {/* {email} */}
        {/* <Grid item xs={12}>
          {miningPackage === "Silver" ? (
            <Typography> $ {count}</Typography>
          ) : null}
          {miningPackage === "Gold" ? (
            <Typography> $ {count}</Typography>
          ) : null}
          {miningPackage === "Platinum" ? (
            <Typography> $ {count}</Typography>
          ) : null}
        </Grid> */}
        <Grid item xs={12}></Grid>
      </Grid>
    </React.Fragment>
  );
}
