import React, { useEffect, useState } from 'react';
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarRoute,
  SideBtnWrap,
  SidebarLinkRoute,
} from './SidebarElements';
import { getCurrentUser, fetchUserAttributes } from 'aws-amplify/auth';

const Sidebar = ({ isOpen, toggle }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const currentUser = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();
        setUser({ ...currentUser, attributes: userAttributes });
      } catch (error) {
        console.error('Error fetching user:', error);
        setUser(null);
      }
    };

    fetchUser();
  }, []);

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLinkRoute data-cy='about' to='/about'>
            About
          </SidebarLinkRoute>

          <SidebarLink
            to='hardware'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            data-cy='hardware'
          >
            Hardware
          </SidebarLink>
          <SidebarLink
            to='hosting'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            data-cy='hosting'
          >
            Hosting
          </SidebarLink>
          <SidebarLink
            to='services'
            onClick={toggle}
            smooth={true}
            duration={500}
            spy={true}
            exact='true'
            offset={-80}
            data-cy='services'
          >
            Cloud Contract
          </SidebarLink>
          {/* <SidebarLinkRoute to="/team">The Team</SidebarLinkRoute> */}
          {/* <SidebarLinkRoute to="/faq">FAQ</SidebarLinkRoute> */}
        </SidebarMenu>
        <SideBtnWrap>
          {user ? (
            <SidebarRoute to={`/premium-dashboard`}>
              {user.attributes.given_name}
            </SidebarRoute>
          ) : (
            <SidebarRoute to='/login'>
              <h1>Sign In</h1>
            </SidebarRoute>
          )}
        </SideBtnWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
