import React from "react";
import AdminDashboard from "../components/AdminDashboard";

function AdminDashboardPage() {
  return (
    <>
      <AdminDashboard />
    </>
  );
}

export default AdminDashboardPage;
