import { useState } from 'react';
import axios from 'axios';
import { fetchAuthSession } from 'aws-amplify/auth';
import { api } from '../utils/enviornment';

export const useDeleteMetaData = () => {
  const [deleting, setDeleting] = useState(false);
  const [deleteSuccess, setDeleteSuccess] = useState(false);
  const [deleteError, setDeleteError] = useState<string | null>(null);

  const deleteMetaData = async (id: string) => {
    setDeleting(true);
    setDeleteError(null);
    setDeleteSuccess(false);

    try {
      const { tokens } = await fetchAuthSession();
      if (!tokens?.accessToken) {
        throw new Error('No access token available');
      }

      await axios.delete(`${api}user/deleteMetaData/${id}`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${tokens.accessToken.toString()}`,
        },
      });

      setDeleteSuccess(true);
    } catch (error: any) {
      setDeleteError(error.message);
    } finally {
      setDeleting(false);
    }
  };

  return { deleteMetaData, deleting, deleteSuccess, deleteError };
};
