import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme, expand }) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}));

export default function Cardinfo({
  img,
  title,
  subheader,
  description1,
  description2,
  description3,
  description4,
  description5,
  description6,
  specs,
}) {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      data-cy='purchase-miner'
      sx={{
        maxWidth: 500,
        width: 300,
        background: '#010606',
        borderRadius: '15px',
      }}
    >
      <CardHeader
        data-cy='card-header'
        title={title}
        subheader={
          <Typography sx={{ color: '#fff' }} data-cy='card-subheader'>
            {subheader}
          </Typography>
        }
        sx={{ textAlign: 'center', justifyContent: 'center', color: '#fff' }}
      />
      <CardMedia
        data-cy='card-media'
        component='img'
        height='50'
        image={img}
        alt='Miner'
        sx={{ height: '100%' }}
      />
      <CardContent data-cy='card-content'>
        <Typography
          data-cy='card-specs'
          variant='body2'
          color='text.secondary'
          sx={{ textAlign: 'center', justifyContent: 'center', color: '#fff' }}
        >
          {specs}
        </Typography>
      </CardContent>
      <CardActions disableSpacing data-cy='card-actions'>
        <Button
          data-cy='get-started-button'
          color='success'
          variant='contained'
          size='medium'
          sx={{ margin: 'auto', width: '80%' }}
          to='/hosting'
          href='/hosting'
        >
          Get Started
        </Button>
        <ExpandMore
          data-cy='expand-more-button'
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label='show more'
        >
          <ExpandMoreIcon sx={{ color: '#fff' }} />
        </ExpandMore>
      </CardActions>
      <Collapse
        in={expanded}
        timeout='auto'
        unmountOnExit
        data-cy='card-collapse'
      >
        <CardContent data-cy='expanded-content'>
          <Typography sx={{ color: '#fff' }} paragraph data-cy='description-1'>
            {description1}
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph data-cy='description-2'>
            {description2}
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph data-cy='description-3'>
            {description3}
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph data-cy='description-4'>
            {description4}
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph data-cy='description-5'>
            {description5}
          </Typography>
          <Typography sx={{ color: '#fff' }} paragraph data-cy='description-6'>
            {description6}
          </Typography>
        </CardContent>
      </Collapse>
    </Card>
  );
}
