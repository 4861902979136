import Icon from '../../images/Bitcoinlogo/bitcoin0.svg';
import Icon2 from '../../images/svg-3.svg';
import Icon3 from '../../images/Bitcoinlogo/bitcoin-on-cloud-with-up-arrow-symbol-svgrepo-com (1).svg';
export const hostingObjOne = {
  title: ` 
  No strings Attached Package
  `,
  // subheader: "12c/kWh CAD Or 9.6c/kWh USD all inclusive ",
  description1: `• No Contract or Commitment`,
  description2: `• Freedom to cancel at anytime and request your miners`,
  description3: `• Electricity, Internet & Rent`,
  description4: `• Free and Unlimited support`,
  description5: `• 97%+ Uptime`,
  description6: `• One-time install fee : $40CAD/unit or $32 USD/unit`,
  img: Icon3,
  specs: '12c/kWh CAD Or 9.6c/kWh USD all inclusive',
};
export const hostingObjTwo = {
  title: '6 Month Hosting Contract',
  // subheader: "11.3c/kWh CAD all inclusive or 9c/kWh USD all inclusive",
  description1: `• Electricity, Internet & Rent`,
  description2: `• Free and Unlimited support`,
  description3: `• 97%+ Uptime`,
  description4: `• One-time install fee : $30CAD/$25 USD per unit`,
  description5: `• S19 Models Overclocking available for S19 models $50CAD/$40USD per unit if requested.`,
  description6: ``,
  // description6: `Electricity, Internet & Rent`,
  img: Icon2,
  specs: '11.3c/kWh CAD all inclusive or 9c/kWh USD all inclusive',
};
export const hostingObjThree = {
  title: '12 Month Hosting Contract',
  // subheader: "11.3c/kWh CAD all inclusive or 9c/kWh USD all inclusive",
  description1: `• Electricity, Internet & Rent`,
  description2: `• Free and Unlimited support`,
  description3: `• 97%+ Uptime`,
  description4: `• No installation fees`,
  description5: `• S19models Overclocking and fine tuning option available for free if requested.`,
  description6: `• Private Support Chat with our team`,
  img: Icon,
  specs: '11.3c/kWh CAD all inclusive or 9c/kWh USD all inclusive',
};
