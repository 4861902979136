import React from 'react';
import { Button } from '../ButtonElements';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  Column1,
  Column2,
  TextWrapper,
  TopLine,
  Heading,
  Subtitle,
  BtnWrap,
  ImgWrap,
  Img,
} from './InfoElements';

const InfoSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  headline,
  description,
  buttonLabel,
  img,
  alt,
  id,
  primary,
  darkText,
  dark,
  dark2,
}) => {
  return (
    <>
      <InfoContainer lightBg={lightBg} id={id} data-cy='info-container'>
        <InfoWrapper data-cy='info-wrapper'>
          <InfoRow imgStart={imgStart} data-cy='info-row'>
            <Column1 data-cy='column-1'>
              <TextWrapper data-cy='text-wrapper'>
                <TopLine data-cy='top-line'>{topLine}</TopLine>
                <Heading lightText={lightText} data-cy='heading'>
                  {headline}
                </Heading>
                <Subtitle darkText={darkText} data-cy='subtitle'>
                  {description}
                </Subtitle>
                <BtnWrap data-cy='button-wrapper'>
                  <Button
                    to='hardware'
                    smooth={true}
                    duration={500}
                    spy={true}
                    exact='true'
                    offset={-80}
                    primary={primary ? 1 : 0}
                    dark={dark ? 1 : 0}
                    dark2={dark2 ? 1 : 0}
                    data-cy='info-button'
                  >
                    {buttonLabel}
                  </Button>
                </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2 data-cy='column-2'>
              <ImgWrap data-cy='img-wrap'>
                <Img src={img} alt={alt} data-cy='info-image' />
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
