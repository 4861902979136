import './customLoadingIcon.css';
import React from 'react';

const CustomLoadingIcon = () => {
  return (
    <div className='loader-container'>
      <div className='loader'></div>
    </div>
  );
};

export default CustomLoadingIcon;
