export const hostingObjOne = {
  title: ` 
  Antminer S19J Pro 104TH
  `,
  // subheader: "Release July 2021",
  description1: `Release July 2021`,
  description2: `• SizeL 195 x 290 x 370 mm`,
  description3: `• Weight: 13.2 kg`,
  description4: `• Noise level: 75 db`,
  description5: `• Power: 3068 W ±3%`,

  // description5: `• 97%+ Uptime`,
  // description6: `• One-time install fee : $40CAD/unit or $32 USD/unit`,
  img: require("./images/S19j.png"),
  // specs: "12c/kWh CAD Or 9.6c/kWh USD all inclusive",
};

export const hostingObjTwo = {
  title: "Antminer S19J Pro 100TH",
  // subheader: "Release: June 2021",
  description1: `Release: June 2021`,
  description2: `• Size: 195 x 290 x 370 mm`,
  description3: `• Weight: 13.2 kg`,
  description4: `• Noise level: 75 db`,
  description5: `• Power: 3050 W ±3%`,

  // description6: ``,
  // des  cription6: `Electricity, Internet & Rent`,
  img: require("./images/S19j.png"),
  // specs: "11.3c/kWh CAD all inclusive or 9c/kWh USD all inclusive",
};
export const hostingObjThree = {
  title: `Antminer Model L7 9050MH/s`,
  // subheader: "Release: February 2022",
  description1: `Release: February 2022 `,
  description2: `• Size: 195 x 290 x 370mm  `,
  description3: `• Weight: 15 kg`,
  description4: `• Noise level: 75db`,
  description5: `• Power: 3425W`,
  // description5: `• S19models Overclocking and fine tuning option available for free if requested.`,
  // description6: `• Private Support Chat with our team`,
  img: require("./images/L7.png"),
  // specs: "11.3c/kWh CAD all inclusive or 9c/kWh USD all inclusive",
};
