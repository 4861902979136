import { createSlice } from "@reduxjs/toolkit";

export const counterSlice = createSlice({
  name: "counter",
  initialState: {
    count: 0,
    tier: "",
    hashrate: "",
  },
  reducers: {
    PackageOne: (state) => {
      state.count = 500;
      state.tier = "Gold";
      state.hashrate = "10TH/s";
    },
    PackageTwo: (state) => {
      state.count = 700;
      state.tier = "Silver";
      state.hashrate = "50TH/s";
    },
    PackageThree: (state) => {
      state.count = 1000;
      state.tier = "Platinum";
      state.hashrate = "104TH/s";
    },
  },
});

// Action creators are generated for each case reducer function
export const { PackageOne, PackageTwo, PackageThree } = counterSlice.actions;

export default counterSlice.reducer;
