import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';

interface ProtectedRouteProps extends RouteProps {
  component: React.ComponentType<any>;
  allowedGroups: string[];
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  allowedGroups,
  ...rest
}) => {
  const [authState, setAuthState] = useState<{
    isAuthenticated: boolean;
    isLoading: boolean;
    userGroups: string[];
  }>({
    isAuthenticated: false,
    isLoading: true,
    userGroups: [],
  });

  useEffect(() => {
    const checkAuthStatus = async () => {
      try {
        const user = await getCurrentUser();
        const session = await fetchAuthSession();
        const idToken = session.tokens?.idToken;
        const groups = idToken?.payload['cognito:groups'] as
          | string[]
          | undefined;

        console.log('User:', user);
        console.log('ID Token payload:', idToken?.payload);
        console.log('User groups:', groups);

        setAuthState({
          isAuthenticated: true,
          isLoading: false,
          userGroups: groups || [],
        });
      } catch (error) {
        console.error('Authentication error:', error);
        setAuthState({
          isAuthenticated: false,
          isLoading: false,
          userGroups: [],
        });
      }
    };

    checkAuthStatus();
  }, []);

  useEffect(() => {
    console.log('Auth state updated:', authState);
  }, [authState]);

  console.log('Allowed Groups for this URL:', allowedGroups);
  console.log("Current user's auth groups:", authState.userGroups);

  if (authState.isLoading) {
    return <div>Loading...</div>;
  }

  const hasAllowedGroup = authState.userGroups.some((group) =>
    allowedGroups.includes(group)
  );

  console.log('Has allowed group:', hasAllowedGroup);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!authState.isAuthenticated) {
          console.log('User is not authenticated, redirecting to login');
          return <Redirect to='/login' />;
        }
        if (!hasAllowedGroup) {
          console.log(
            'User does not have required group, redirecting to access denied'
          );
          return <Redirect to='/access-denied' />;
        }
        console.log('Rendering protected component');
        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;
