import StoreItems from "./StoreItems";
import StoreNav from "./StoreNav";
import { Paper } from "@mui/material";
import CheckoutFooter from "./CheckoutFooter";
import React, { useState } from "react";
// import setStorePrice from "../../redux/stroreTotal";
// import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
function MinerStore() {
  const [cartCount, setCartCount] = useState(0);
  const [Total, setTotal] = useState(0);
  // const dispatch = useDispatch();
  const { price } = useSelector((state) => state.price);
  const addToCart = () => {
    setCartCount(cartCount + 1);

    console.log(price);
  };

  return (
    <>
      <div style={{ marginTop: "80px" }}>
        <StoreNav cartCount={cartCount} />
        <div
          style={{ marginTop: "50px", marginLeft: "50px", marginRight: "50px" }}
        >
          <Paper>
            <StoreItems
              Total={Total}
              setTotal={setTotal}
              addToCart={addToCart}
              // onClick={() => dispatch(setStorePrice(price))}
            />
          </Paper>
        </div>
      </div>
      <div style={{ color: "gray", position: "sticky", bottom: 0 }}>
        <CheckoutFooter Total={Total} />
      </div>
    </>
  );
}

export default MinerStore;
