import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import List from '@mui/material/List';
import Button from '@mui/material/Button';
import {
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
} from '@mui/material';
import { MainListItems, SecondaryListItems } from './listItems';
import { signOut } from 'aws-amplify/auth';

const drawerWidth = 240;

const miners = [
  {
    id: 'starter',
    title: 'Starter Miner',
    description:
      'Perfect for beginners looking to learn more about cryptocurrency mining.',
    imageUrl: 'StarterMinerImage',
  },
  {
    id: 'advanced',
    title: 'Advanced Miner',
    description:
      'Ideal for experienced miners seeking higher performance and efficiency.',
    imageUrl: 'AdvancedMinerImage',
  },
  {
    id: 'professional',
    title: 'Professional Miner',
    description:
      'Designed for professional miners demanding the best hash rates and reliability.',
    imageUrl: 'ProfessionalMinerImage',
  },
];

// const handleBuyMiner = (minerId: any) => {
//   console.log(`Redirect to buy miner with id: ${minerId}`);
// };

const handleLogout = async () => {
  try {
    await signOut();
    console.log('Signed out');
    window.location.reload();
  } catch (error) {}
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop: string) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop: string) => prop !== 'open',
})<{ open: boolean }>(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const StyledLogoutButton = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.success.main,
  color: theme.palette.success.contrastText,
  fontWeight: 'bold',
  padding: theme.spacing(1),
  borderRadius: '4px',
  '&:hover': {
    backgroundColor: theme.palette.success.dark,
  },
}));
const mdTheme = createTheme();

export default function AdminDashboard() {
  const [open, setOpen] = React.useState(true);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position='absolute' open={open}>
          <Toolbar
            sx={{
              pr: '24px',
            }}
          >
            <IconButton
              edge='start'
              color='inherit'
              aria-label='open drawer'
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component='h1'
              variant='h6'
              color='inherit'
              noWrap
              sx={{ flexGrow: 1 }}
            ></Typography>

            <Link>
              <StyledLogoutButton
                data-cy='logout-button'
                onClick={handleLogout}
                variant='contained'
              >
                Logout
              </StyledLogoutButton>
            </Link>
          </Toolbar>
        </AppBar>
        <Drawer variant='permanent' anchor='left' open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '64px',
              px: [1],
            }}
          >
            <div style={{ flex: 1 }} />

            <IconButton
              sx={{
                flex: 0,
              }}
              onClick={toggleDrawer}
            >
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List>
            <MainListItems />
          </List>
          <Divider />
          <List>
            <SecondaryListItems />
          </List>
        </Drawer>

        <Box
          component='main'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth={false} sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ minHeight: '75vh', p: 2 }}>
                  <Box
                    component='main'
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === 'light'
                          ? `linear-gradient(45deg, ${theme.palette.grey[100]} 30%, ${theme.palette.grey[300]} 90%)`
                          : `linear-gradient(45deg, ${theme.palette.grey[900]} 30%, ${theme.palette.grey[700]} 90%)`,
                      flexGrow: 1,
                      height: '100vh',
                      overflow: 'auto',
                    }}
                  >
                    <Toolbar />
                    <Container maxWidth='lg' sx={{ mt: 4, mb: 4 }}>
                      <Typography
                        component='h2'
                        variant='h4'
                        align='center'
                        sx={{ mb: 2 }}
                        data-cy='welcome-guest'
                      >
                        Welcome to your custom miner dashboard!
                      </Typography>
                      <Typography
                        component='p'
                        variant='subtitle1'
                        align='center'
                        sx={{ mb: 4 }}
                      >
                        Purchase one of our miners to get started mining
                        Bitcoin.
                      </Typography>
                      <Grid container spacing={3}>
                        {miners.map(
                          (miner: {
                            id: React.Key | null | undefined;
                            title: {} | null | undefined;
                            imageUrl: string | undefined;
                            description:
                              | boolean
                              | React.ReactChild
                              | React.ReactFragment
                              | React.ReactPortal
                              | null
                              | undefined;
                          }) => (
                            <Grid item xs={12} sm={6} md={4} key={miner.id}>
                              <Card
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  height: '100%',
                                  boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                                  ':hover': {
                                    boxShadow: '0 8px 16px 0 rgba(0,0,0,0.3)',
                                  },
                                }}
                              >
                                <CardHeader
                                  title={miner.title}
                                  subheader='Suitable for:'
                                  titleTypographyProps={{ align: 'center' }}
                                  subheaderTypographyProps={{ align: 'center' }}
                                  sx={{
                                    backgroundColor: (theme) =>
                                      theme.palette.grey[200],
                                  }}
                                  data-cy={`${miner.title}-miner`}
                                />
                                <CardMedia
                                  component='img'
                                  // image={miner.imageUrl}

                                  sx={{ maxHeight: 194 }}
                                />
                                <CardContent>
                                  <Typography
                                    variant='body2'
                                    color='text.secondary'
                                  >
                                    {miner.description}
                                  </Typography>
                                </CardContent>
                                <CardActions
                                  sx={{
                                    justifyContent: 'center',
                                    paddingBottom: 2,
                                  }}
                                >
                                  <Button
                                    size='large'
                                    color='primary'
                                    // onClick={() => handleBuyMiner(miner.id)}
                                  >
                                    Buy {miner.title}
                                  </Button>
                                </CardActions>
                              </Card>
                            </Grid>
                          )
                        )}
                      </Grid>
                    </Container>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
