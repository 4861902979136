export enum UserRole {
  Admin = 'Admin',
  Guest = 'Guest',
  Premium = 'Premium',
}

export interface DMPSUserData {
  id: string;
  createdAt: string;
  given_name: string;
  family_name: string;
  email: string;
  amount_paid: number;
  status: string;
  amount_owed: number;
  number_of_mining_machines: number;
}
