import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { IconContext } from "react-icons/lib";
import { animateScroll as scroll } from "react-scroll";
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavBtnAlt,
  NavLogo,
  NavMenu,
  NavBtn,
} from "./NavbarElements";

const NavbarHomeRedirect = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleHome = () => {
    scroll.scrollToTop();
  };

  return (
    <IconContext.Provider value={{ color: "#fff" }}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo onClick={toggleHome} to="/">
            DMPS Mining
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavBtnAlt
                to="/about"
                // smooth={true}
                // duration={500}
                // spy={true}
                // exact="true"
                // offset={-80}
                data-cy="about"
              >
                About
              </NavBtnAlt>
            </NavItem>
            <NavItem>{/* <NavBtnAlt to="/Faq">FAQ</NavBtnAlt> */}</NavItem>
            <NavItem>
              <NavBtnAlt
                to="/"
                // smooth={true}
                // duration={500}
                // spy={true}
                // exact="true"
                // offset={-80}
                data-cy="hardware"
              >
                Hardware
              </NavBtnAlt>
            </NavItem>
            <NavItem>
              <NavBtnAlt
                to="/"
                // smooth={true}
                // duration={500}
                // spy={true}
                // exact="true"
                // offset={-80}
                data-cy="hosting"
              >
                Hosting
              </NavBtnAlt>
            </NavItem>

            <NavItem>{/* <NavBtnAlt to="/team">Team</NavBtnAlt> */}</NavItem>
            <NavItem>
              <NavBtnAlt
                to="services"
                // onClick={toggle}
                // smooth={true}
                // duration={500}
                // spy={true}
                // exact="true"
                // offset={-80}
                data-cy="services"
              >
                Cloud Contract
              </NavBtnAlt>
            </NavItem>
            <NavItem>
              <NavBtnAlt
                to="/"
                // smooth={true}
                // duration={500}
                // spy={true}
                // exact="true"
                // offset={-80}
                data-cy="signup"
              >
                Contact
              </NavBtnAlt>
            </NavItem>
          </NavMenu>

          <NavBtn>
            {/* <NavBtnLink to={`/account/${userProfile._id}`}>Account</NavBtnLink> */}
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default NavbarHomeRedirect;
