import React from "react";
import Login from "../components/Login";
import ScrollToTop from "../components/ScrollToTop";

function payments() {
  return (
    <>
      <ScrollToTop />
      <Login />
    </>
  );
}

export default payments;
