import React from "react";
import Dashboard from "../components/Dashboard";

function DashboardPage() {
  return (
    <>
      <Dashboard />
    </>
  );
}

export default DashboardPage;
