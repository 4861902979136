import React, { useState } from "react";
import Blog from "../components/About/Blog";
// import Navbar from "../components/Navbar";
import ScrollToTop from "../components/ScrollToTop";
import Sidebar from "../components/Sidebar";
import NavbarHomeRedirect from "../components/Navbar/NavbarHomeRedirect";
function Aboutpage() {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };
  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      {/* <Navbar toggle={toggle} /> */}
      <NavbarHomeRedirect toggle={toggle} />
      <ScrollToTop />
      <div style={{ marginTop: "100px" }}>
        <Blog />
      </div>
    </>
  );
}

export default Aboutpage;
