import sam from './images/sam.jpg'
import matt from './images/matt.jpg'
import derek from './images/derek.JPG'
import Jason from './images/jason.png'
export const data = [
  {
    id: 1,
    title: 'Derek Poon',
    content: `CEO / Founder`,
    image: derek,
    Contact: '876-345-6789'
  },
  {
    id: 2,
    title: 'Samuel Lawrence',
    content: `Lead Engineer`,
    image: sam
  },
  {
    id: 3,
    title: 'Matthew Huang',
    content: `Junior Software developer`,
    image: matt
  },
  {
    id: 4,
    title: 'Jason Lee',
    content: `Logistics Manager`,
    image: Jason
  }
  // {
  //   id: 4,
  //   title: "Santosh Samtani",
  //   content: `CFO`,
  //   image: sam,
  // },
]
