import React from "react";
import PaymentInstructions from "../components/PaymentInstructions";
import ScrollToTop from "../components/ScrollToTop";

function instructions() {
  return (
    <>
      <ScrollToTop />
      <PaymentInstructions />
    </>
  );
}

export default instructions;
