import * as React from "react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Title from "./Title";

// Generate Order Data
function createData(
  id: number,
  date: string,
  averageHashrate: number,
  btcAddress: string,
  paymentMethod: string,
  amount: number
) {
  return { id, date, averageHashrate, btcAddress, paymentMethod, amount };
}

const rows = [
  createData(0, "16 Mar, 2019", 1000, "Tupelo, MS", "BTC ⠀•••• Ex456", 1120.0),
  createData(1, "16 Mar, 2019", 986, "London, UK", "BTC ⠀•••• Ex456", 986.0),
  createData(2, "16 Mar, 2019", 1003, "Boston, MA", "BTC •••• Ex456", 1200.0),
  createData(3, "16 Mar, 2019", 898, "Gary, IN", "BTC •••• Ex456", 1000.0),
  createData(
    4,
    "15 Mar, 2019",
    903,
    "Long Branch, NJ",
    "BTC •••• Bx465",
    500.0
  ),
];

function preventDefault(event: { preventDefault: () => void }) {
  event.preventDefault();
}

export default function Orders() {
  return (
    <React.Fragment>
      <Title>Hashrate / Miner Orders</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Average Hashrate</TableCell>
            <TableCell>Ship To</TableCell>
            <TableCell align="right">Payout</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.date} </TableCell>
              <TableCell>{row.averageHashrate + " MH/s"} </TableCell>
              <TableCell>{row.paymentMethod}</TableCell>
              <TableCell align="right">{`$${row.amount}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
        See more orders
      </Link>
    </React.Fragment>
  );
}
