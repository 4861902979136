import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counter";
import emailReducer from "./email";
import nameReducer from "./name";
import walletReducer from "./wallet";
import priceReducer from "./stroreTotal";

export default configureStore({
  reducer: {
    counter: counterReducer,
    email: emailReducer,
    wallet: walletReducer,
    name: nameReducer,
    price: priceReducer,
  },
});
