import CssBaseline from "@mui/material/CssBaseline";
import CustomerAdminTable from "./components/CustomerAdminTable";

export interface UserData {
  firstName: string;
  lastName: string;
  email: string;
  hashrate: number;
  status: string;
  amountPaid: number;
  amountOwed: number;
  numberOfMiners: number;
}

function TableContainer(props: any) {
  return (
    <div>
      <CssBaseline />

      <CustomerAdminTable />
    </div>
  );
}

export default TableContainer;
