import React, { useState, useEffect } from 'react';
import { FaBars } from 'react-icons/fa';
import { IconContext } from 'react-icons/lib';
import { fetchUserAttributes, fetchAuthSession } from 'aws-amplify/auth';

import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavBtn,
  NavBtnLink,
  NavBtnAlt,
} from './NavbarElements';

interface NavbarProps {
  toggle: () => void;
}

interface UserAttributes {
  given_name: string;
}

interface User {
  isAuthenticated: boolean;
  groups: string[];
  attributes: UserAttributes;
}

const Navbar: React.FC<NavbarProps> = ({ toggle }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [user, setUser] = useState<User | null>(null);

  const changeNav = () => {
    if (window.scrollY >= 80) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeNav);
    return () => window.removeEventListener('scroll', changeNav);
  }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        // const currentUser = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();
        const session = await fetchAuthSession();
        const idToken = session.tokens?.idToken;
        const groups = idToken?.payload['cognito:groups'] as
          | string[]
          | undefined;

        setUser({
          isAuthenticated: true,
          groups: groups || [],
          attributes: {
            given_name: userAttributes.given_name || '',
          },
        });
      } catch (error) {
        console.error('Error fetching user:', error);
        setUser(null);
      }
    };

    fetchUser();
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  // const renderNavLink = (
  //   to: string,
  //   label: string,
  //   allowedGroups: string[]
  // ) => {
  //   if (!user || !user.isAuthenticated) return null;
  //   if (
  //     allowedGroups.length === 0 ||
  //     user.groups.some((group) => allowedGroups.includes(group))
  //   ) {
  //     return (
  //       <NavItem>
  //         <NavLinks
  //           to={to}
  //           smooth={true}
  //           duration={500}
  //           spy={true}
  //           exact='true'
  //           offset={-80}
  //           data-cy={to}
  //         >
  //           {label}
  //         </NavLinks>
  //       </NavItem>
  //     );
  //   }
  //   return null;
  // };

  const renderDashboardLink = () => {
    if (!user || !user.isAuthenticated) return null;
    if (user.groups.includes('Admin')) {
      return (
        <NavBtnLink to='/admin' data-cy='admin-dashboard'>
          Admin Dashboard
        </NavBtnLink>
      );
    } else if (user.groups.includes('Premium')) {
      return (
        <NavBtnLink to='/premium-dashboard' data-cy='premium-dashboard'>
          Premium Dashboard
        </NavBtnLink>
      );
    } else {
      return (
        <NavBtnLink to='/guest-dashboard' data-cy='guest-dashboard'>
          Guest Dashboard
        </NavBtnLink>
      );
    }
  };

  return (
    <IconContext.Provider value={{ color: '#fff' }}>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo onClick={scrollToTop} to='/'>
            DMPS Mining
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavBtnAlt to='/about' data-cy='about'>
                About
              </NavBtnAlt>
            </NavItem>
            <NavItem>
              <NavLinks
                to='hardware'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                data-cy='hardware'
              >
                Hardware
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to='hosting'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                data-cy='hosting'
              >
                Hosting
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to='services'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                data-cy='services'
              >
                Cloud Contract
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to='signup'
                smooth={true}
                duration={500}
                spy={true}
                exact='true'
                offset={-80}
                data-cy='signup'
              >
                Contact
              </NavLinks>
            </NavItem>
          </NavMenu>

          <NavBtn>
            {user && user.isAuthenticated ? (
              renderDashboardLink()
            ) : (
              <NavBtnLink data-cy='sign-in' to={'/login'}>
                Sign In
              </NavBtnLink>
            )}
          </NavBtn>
        </NavbarContainer>
      </Nav>
    </IconContext.Provider>
  );
};

export default Navbar;
