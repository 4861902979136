import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { data } from "./data";

const theme = createTheme();

export default function StoreCard({ addToCart, Total, setTotal }) {
  const addToTotal = (item) => {
    addToCart();
    setTotal(Total + item.price);
  };
  return (
    <ThemeProvider theme={theme}>
      <main>
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            {data.map((item, idx) => (
              <Grid item key={idx} xs={12} sm={6} md={3}>
                <Card
                  sx={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <CardMedia
                    component="img"
                    image={item.image}
                    sx={{
                      // 16:9
                      pt: "5%",
                    }}
                    alt="random"
                  />
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {item.title}
                    </Typography>
                    <Typography>{item.content}</Typography>
                  </CardContent>
                  <CardActions>
                    <div>
                      <Button variant="contained">Buy</Button>
                      <Button
                        size="small"
                        sx={{ marginLeft: "5px" }}
                        onClick={() => addToTotal(item)}
                      >
                        Add to Cart
                      </Button>
                    </div>
                    <div style={{ marginLeft: "auto" }}>
                      <Typography
                        sx={
                          {
                            // fontFamily: "'Playfair Display'",
                            // fontFamily: "'Rubik Mono One'",
                          }
                        }
                      >
                        {"$" + item.price}
                      </Typography>
                    </div>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </ThemeProvider>
  );
}
